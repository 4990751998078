import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { InputDecimal } from 'shared/form/InputDecimal'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { useDebouncedCallback } from 'shared/hooks/util'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'
import { TableColumn, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'
import { parseAddress } from 'shared/util/google-maps'

import classes from './ReportTable.module.scss'

interface Props {
  session: Model.Session
}

export const ReportTableRow: React.FC<Props> = ({ session }) => {
  const [, setFinantialInfo] = useState(session.finantial_info || {})
  const handleSessionFinantialCost = useDebouncedCallback(
    (finantial_info: object) => {
      const body = toFormData({ finantial_info })
      fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
        .then(response => {
          if (!response.ok) {
            throw new Error()
          }
        })
        .catch(() => alert('Falha ao atualizar custo do ensaio'))
    },
    [],
    3000
  )
  const handleSessionCost = (key: string) => (value: number) => {
    setFinantialInfo(info => {
      const finantial = { ...info, [key]: value }
      handleSessionFinantialCost(finantial)
      return finantial
    })
  }
  const handleSessionPrice = useDebouncedCallback(
    (price: number) => {
      const body = toFormData({ price })
      fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
        .then(response => {
          if (!response.ok) {
            throw new Error()
          }
        })
        .catch(() => alert('Falha ao atualizar preço do ensaio'))
    },
    [],
    3000
  )
  const handleServiceProductSessionCost = useDebouncedCallback(
    (id: number, cost: number) => {
      const body = toFormData({ cost })
      fetch(`/api/sessions/${session.id}/${id}`, { body, method: 'POST' })
        .then(response => {
          if (!response.ok) {
            throw new Error()
          }
        })
        .catch(() => alert('Falha ao atualizar custo do entregável'))
    },
    [],
    3000
  )
  const handleServiceProductSessionCostChange = (id: number) => (cost: number) => {
    handleServiceProductSessionCost(id, cost)
  }
  const address = parseAddress(session.address?.address_components)
  return (
    <TableRow key={session.id} className={classes.row}>
      <TableColumn align="center" className={classes.id}>
        <Button className={classes.button} size="sm" target="_blank" to={`/sessions/${session.id}`}>
          <span>{session.id}</span>
          <Icon.AngleRight />
        </Button>
        <div className={classes.status}>
          <Badge color={getSessionStatusColor(session.status)} size="sm">
            {getSessionStatusDescription(session.status)}
          </Badge>
        </div>
        <div className={classes.date}>
          {session.scheduled_for ? formatISO(session.scheduled_for!, 'dd/MM/yyyy HH:mm') : '-'}
          {/*session.status === SessionStatus.CANCELED
            ? formatISO(session.canceled_at!, 'dd/MM/yyyy HH:mm')
          : formatISO(session.scheduled_for!, 'dd/MM/yyyy HH:mm')*/}
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.client}>
        <div>
          <Link to={`/clients/${session.client.id}`} target="_blank">
            {session.client.name}
          </Link>
        </div>
        <div>
          Referência: <strong>{session.vertical_data?.reference || '-'}</strong>
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.dates}>
        <div>
          Criação: <strong>{formatISO(session.created_at!, 'dd/MM/yyyy HH:mm')}</strong>
        </div>
        <div>Cancelamento: {session.canceled_at ? formatISO(session.canceled_at, 'dd/MM/yyyy HH:mm') : '-'}</div>
      </TableColumn>
      <TableColumn align="center" className={classes.area}>
        {session.vertical_data?.area || '-'} m²
      </TableColumn>
      <TableColumn align="center" className={classes.host}>
        {/*<InputGroup size="sm">
          <InputGroupText>R$</InputGroupText>
          <InputDecimal
            initial={session.finantial_info?.host_cost}
            onChange={handleSessionCost('host_cost')}
            scale={2}
            placeholder="0,00"
          />
        </InputGroup>*/}
        <div className={classes.months}>{session.service.host_prize || '-'} meses</div>
      </TableColumn>
      <TableColumn align="center" className={classes.products}>
        {session.service.products?.map(({ id, item }) => item && <div key={id}>{item.title}</div>)}
      </TableColumn>
      <TableColumn align="center" className={classes.price}>
        <div className={classes.grid}>
          <div>Serviço</div>
          <InputGroup size="sm">
            <InputGroupText>R$</InputGroupText>
            <InputDecimal scale={2} initial={session.price} placeholder="0,00" onChange={handleSessionPrice} />
          </InputGroup>
          {/*<div>Excedente (m²)</div>
          <InputGroup size="sm">
            <InputGroupText>R$</InputGroupText>
            <InputDecimal scale={2} placeholder="0,00" />
          </InputGroup>*/}
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.deliverables}>
        <div className={classes.grid}>
          {session.products?.map(
            ({ cost, id, item, provider }) =>
              item.work_type !== 4 && (
                <Fragment key={id}>
                  <div>{item.label}</div>
                  <div>{provider?.name || '-'}</div>
                  <InputGroup size="sm">
                    <InputGroupText>R$</InputGroupText>
                    <InputDecimal
                      initial={cost}
                      onChange={handleServiceProductSessionCostChange(id)}
                      scale={2}
                      placeholder="0,00"
                    />
                  </InputGroup>
                </Fragment>
              )
          )}
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.costs}>
        <div className={classes.grid}>
          <div>Custo Planta</div>
          <InputGroup size="sm">
            <InputGroupText>R$</InputGroupText>
            <InputDecimal
              initial={session.finantial_info?.blueprint_cost}
              onChange={handleSessionCost('blueprint_cost')}
              scale={2}
              placeholder="0,00"
            />
          </InputGroup>
          <div>Outros Custos</div>
          <InputGroup size="sm">
            <InputGroupText>R$</InputGroupText>
            <InputDecimal
              initial={session.finantial_info?.other_cost}
              onChange={handleSessionCost('other_cost')}
              scale={2}
              placeholder="0,00"
            />
          </InputGroup>
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.sla}>
        {session.service.sla || '-'}
      </TableColumn>
      <TableColumn align="center" className={classes.address}>
        <div>
          {address.get('street')}
          {address.has('number') && `, ${address.get('number')}`}
          {session.vertical_data?.unit && ` - ${session.vertical_data?.unit}`}
        </div>
        <div>
          {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
        </div>
      </TableColumn>
      <TableColumn align="center" className={classes.month}>
        {session.scheduled_for && formatISO(session.scheduled_for, 'MMMM/yyyy')}
      </TableColumn>
    </TableRow>
  )
}
