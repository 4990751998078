import React, { forwardRef, useState } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { IMaskInput } from 'react-imask'
import { format, isValid, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

registerLocale('ptBR', ptBR)
setDefaultLocale('ptBR')

interface Props {
  initial?: string | null
  onChange?: (_: string) => void
  value?: string
}

interface InputDateProps {
  onClick?: () => void
  value?: string
}

const Input = forwardRef<HTMLInputElement, InputDateProps>((props, ref) => {
  return <IMaskInput mask="00/00/00[00] 00:00" inputRef={ref} {...props} />
})

export const InputDateTime: React.FC<Props> = ({ initial, onChange, value }) => {
  const [date, setDate] = useState<Date | undefined>(() => {
    if (initial) {
      return parseISO(initial)
    }
  })
  const handleChange = (date: Date) => {
    setDate(date)
    onChange?.(isValid(date) ? format(date, `yyyy-MM-dd'T'HH:mm`) : '')
  }
  return (
    <DatePicker
      className="form-control"
      customInput={<Input />}
      onChange={handleChange}
      dateFormat="dd/MM/yyyy HH:mm"
      placeholderText="Data"
      selected={date}
      showTimeSelect
      value={value}
    />
  )
}
