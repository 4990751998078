import React, { useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Card, CardBody } from 'shared/bootstrap/card'
import { ProviderStatus } from 'shared/enum/provider'
import { InputCheck } from 'shared/form/InputCheck'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util'

import { useProviderContext } from './ProviderContext'

import classes from './ProviderViewInfo.module.scss'

export const ProviderViewInfo: React.FC = () => {
  const [provider, setProvider] = useProviderContext()
  const [value, setValue] = useState({
    status: provider.status
  })
  const handleSave: React.MouseEventHandler = event => {
    const button = event.target as HTMLButtonElement
    button.setAttribute('disabled', 'disabled')
    const body = toFormData(value)
    fetch(`/api/providers/${provider.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      .then(({ data }) => setProvider(data))
      .catch(() => alert('Falha ao atualizar fornecedor'))
      .finally(() => button.removeAttribute('disabled'))
  }
  const inputCheckStatus = (providerStatus: ProviderStatus) => ({
    checked: Boolean((value.status & providerStatus) > 0),
    onChange: (checked: boolean) => {
      setValue(({ status, ...value }) => {
        if (checked) {
          return { ...value, status: status | providerStatus }
        } else {
          return { ...value, status: status & (~providerStatus) }
        }
      })
    }
  })
  return (
    <div className={classes.content}>
      <Card className={classes.info} shadow>
        <CardBody>
          <div className={classes.icon}>
            {provider.user.profile_image_path ? (
              <img src={provider.user.profile_image_path} alt="Foto de perfil" />
            ) : (
              <Icon.User />
            )}
          </div>
          <h1>{provider.name}</h1>
          <h2>
            {provider.neighborhood.name}, {provider.neighborhood.city.name}
          </h2>
          <hr />
          {provider.cpf && <h2>CPF: {provider.cpf}</h2>}
          {provider.rg && <h2>RG: {provider.rg}</h2>}
          {provider.user.email && <h2>{provider.user.email}</h2>}
          {provider.user.phone && <h2>{provider.user.phone}</h2>}
        </CardBody>
      </Card>
      <Card shadow>
        <CardBody>
          <InputCheck {...inputCheckStatus(ProviderStatus.DISABLED)}>
            Desligado
          </InputCheck>
          <InputCheck {...inputCheckStatus(ProviderStatus.LOCAL)}>
            Fornecedor local
          </InputCheck>
          <Button className={classes.button} onClick={handleSave} type="button">
            SALVAR
          </Button>
        </CardBody>
      </Card>
    </div>
  )
}
