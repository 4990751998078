import React, { useEffect, useState } from 'react'

import { FormSelect } from '../../form/FormSelect'
import { RoundButton } from '../../ui/button/RoundButton'
import { Icon } from '../../ui/icon'
import { classNames } from '../../util/class-names'

import { useTableContext } from './Table'

import classes from './TablePaginator.module.scss'

interface TablePaginatorProps {
  className?: string
  meta?: Http.ResponseMeta
  onChange?: (page: number, length: number) => void
  paging?: {
    page: number
    length: number
  }
}

// TODO: reagir a mudanças de meta?

export const TablePaginator: React.FC<TablePaginatorProps> = ({ className, meta, onChange, paging }) => {
  const context = useTableContext()
  const [length, setLength] = useState(paging?.length || 50) // TODO - valor default
  const [page, setPage] = useState(paging?.page || 1)
  const handleLength = (value: string) => {
    const length = Number(value)
    onChange?.(page, length)
  }
  const handlePage = (page: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    onChange?.(page, length)
  }
  useEffect(() => {
    if (meta && meta.per_page !== length) {
      setLength(length)
    }
    if (meta && meta.current_page !== page) {
      setPage(meta?.current_page)
    }
  }, [length, meta, page])
  const renderAfter = () => {
    const count = Math.min(Math.max(meta!.last_page - page, 0), 2)
    return Array.from({ length: count }, (_, i) => (
      <RoundButton
        key={i}
        className={classes.button}
        onClick={handlePage(page + i + 1)}
        size={context.dense ? 'sm' : 'md'}
      >
        {page + i + 1}
      </RoundButton>
    ))
  }
  const renderBefore = () => {
    const count = Math.min(Math.max(0, page - 1), 2)
    return Array.from({ length: count }, (_, i) => (
      <RoundButton key={i} className={classes.button} onClick={handlePage(1 + i)} size={context.dense ? 'sm' : 'md'}>
        {1 + i}
      </RoundButton>
    ))
  }
  return (
    <div className={classNames(classes.pagination, context.dense && classes.dense, className)}>
      <div className={classes.items}>
        <span>Itens por página</span>
        <FormSelect className={classes.select} onChange={handleLength} value={length.toString()}>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="250">250</option>
          <option value="500">500</option>
        </FormSelect>
      </div>
      <div className={classes.buttons}>
        {page > 1 && (
          <RoundButton className={classes.button} onClick={handlePage(1)} size={context.dense ? 'sm' : 'md'}>
            <Icon.AngleLeft />
          </RoundButton>
        )}
        {meta && renderBefore()}
        <RoundButton className={classes.button} type="button" active size={context.dense ? 'sm' : 'md'}>
          {meta?.current_page || '...'}
        </RoundButton>
        {meta && renderAfter()}
        {page < (meta?.last_page || 0) && (
          <RoundButton
            className={classes.button}
            onClick={handlePage(meta!.last_page)}
            size={context.dense ? 'sm' : 'md'}
          >
            <Icon.AngleRight />
          </RoundButton>
        )}
      </div>
      <div>{/* */}</div>
    </div>
  )
}
