import { useEffect, useState } from 'react'

import { toFormData } from '../../util/form-data'

interface Provider {
  id: number
  address: google.maps.GeocoderResult | null
  email: string
  name: string
  phone: string
  status: number
  user: {
    last_access: string | null
  }
}

export interface ProviderDetail extends Provider {
  bio: string | null
  cnpj: string | null
  cpf: string | null
  rg: string | null
  neighborhood: {
    name: string
    city: {
      name: string
    }
  }
  params: {
    em_casa?: {
      id: string | null
    }
    google_calendar: {
      id: string | null
    }
  }
  regions: {
    id: number
    name: string
    region: [number, number][]
  }[]
  user: {
    email: string
    last_access: string | null
    name: string
    phone: string
    profile_image_path: string
  }
}

export function useProviders(params?: Http.Params, paging?: Http.Pagination, setMeta?: (_: Http.ResponseMeta) => void) {
  const [providers, setProviders] = useState<Provider[]>()
  useEffect(() => {
    setProviders(undefined)
    const request = {
      body: toFormData(params || {}),
      method: 'POST'
    }
    const search = new URLSearchParams(params)
    search.set('v', '2') // temporário
    if (paging) {
      search.set('page', `${paging.page}`)
      search.set('length', `${paging.length}`)
    }
    fetch(`/api/providers/search?${search.toString()}`, request)
      .then(response => response.json())
      .then(({ data, meta }) => {
        setProviders(data)
        setMeta?.(meta)
      })
  }, [paging, params, setMeta])
  return providers
}

export function useProvider(key: Model.Key) {
  const [provider, setProvider] = useState<ProviderDetail>()
  useEffect(() => {
    if (key.id) {
      const abort = new AbortController()
      fetch(`/api/providers/${key.id}`, { signal: abort.signal })
        .then(response => response.json())
        .then(({ data }) => setProvider(data))
      return () => abort.abort()
    }
  }, [key])
  return provider
}
