import React, { useCallback, useEffect, useRef } from 'react'
import IMask, { InputMask } from 'imask'

import { classNames } from 'shared/util/class-names'

import { useFormGroupContext } from './FormGroupNew'

import classes from './InputDecimal.module.scss'

interface InputDecimalProps {
  initial?: string | number | null
  onChange?: (_: number) => void
  placeholder?: string
  required?: boolean
  sm?: boolean
  scale?: number
  value?: number | string
}

export const InputDecimal: React.FC<InputDecimalProps> = ({ initial, onChange, scale, sm, value, ...props }) => {
  const formGroup = useFormGroupContext()
  const initialRef = useRef<string | number | undefined | null>(initial)
  const inputMaskRef = useRef<InputMask<IMask.MaskedNumberOptions>>()
  const onChangeRef = useRef(onChange)
  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
  const ref = useCallback(
    (element: HTMLInputElement | null) => {
      if (element) {
        // inputMaskRef.current?.off('accept', handleChange)
        inputMaskRef.current?.destroy()
        inputMaskRef.current = new InputMask<IMask.MaskedNumberOptions>(element, {
          mask: Number,
          padFractionalZeros: true,
          radix: ',',
          scale: scale || 0,
          thousandsSeparator: '.'
        })
        if (initialRef.current) {
          if (typeof initialRef.current === 'number') {
            element.value = initialRef.current.toLocaleString('pt-BR', {
              maximumFractionDigits: scale,
              minimumFractionDigits: scale
            })
          } else {
            element.value = initialRef.current || ''
          }
        }
        inputMaskRef.current.updateValue()
        inputMaskRef.current.on('accept', () => {
          if (inputMaskRef.current) {
            onChangeRef.current?.(inputMaskRef.current.typedValue)
          }
        })
      }
    },
    [scale]
  )
  const params = {
    className: classNames('form-control', sm && 'form-control-sm', classes.input),
    // defaultValue: initial || '',
    id: formGroup?.id,
    ref: ref
  }
  return <input {...props} {...params} />
}
