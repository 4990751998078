export enum SessionStatus {
  NOT_SCHEDULED = 1,
  SCHEDULED = 2,
  /** @deprecated */
  CANCELED = 3,
  PENDING = 4,
  DELIVERED = 5,
  // NOSHOW_CLIENT = 6,
  // NOSHOW_PROVIDER = 7,
  // AT_REVISION = 8,
  CONFIRMED = 9,
  CANCELED_NOT_CHARGED = 10,
  CANCELED_CHARGED = 11,
  CANCELED_DISPLACEMENT = 12
}

export function getSessionStatusColor(status: SessionStatus) {
  switch (status) {
    case SessionStatus.NOT_SCHEDULED:
      return '#212121'
    case SessionStatus.SCHEDULED:
      return '#2196f3'
    case SessionStatus.CANCELED:
      return '#f44336'
    case SessionStatus.PENDING:
      return '#fbc02d'
    case SessionStatus.DELIVERED:
      return '#00b200'
    // case SessionStatus.NOSHOW_CLIENT:
    //   return '#9e9e9e'
    // case SessionStatus.NOSHOW_PROVIDER:
    //   return '#9e9e9e'
    // case SessionStatus.AT_REVISION:
    //   return '#f4511e'
    case SessionStatus.CONFIRMED:
      return '#00796b'
    case SessionStatus.CANCELED_NOT_CHARGED:
      return '#f44336'
    case SessionStatus.CANCELED_CHARGED:
      return '#f44336'
    case SessionStatus.CANCELED_DISPLACEMENT:
      return '#f44336'
    default:
      return ''
  }
}

export function getSessionStatusDescription(status: SessionStatus) {
  switch (status) {
    case SessionStatus.NOT_SCHEDULED:
      return 'Não agendado'
    case SessionStatus.SCHEDULED:
      return 'Agendado'
    case SessionStatus.CANCELED:
      return 'Cancelado'
    case SessionStatus.PENDING:
      return 'Pendente'
    case SessionStatus.DELIVERED:
      return 'Entregue'
    // case SessionStatus.NOSHOW_CLIENT:
    //   return 'Cliente no-show'
    // case SessionStatus.NOSHOW_PROVIDER:
    //   return 'Fornecedor no-show'
    // case SessionStatus.AT_REVISION:
    //   return 'Em revisão'
    case SessionStatus.CONFIRMED:
      return 'Confirmado'
    case SessionStatus.CANCELED_NOT_CHARGED:
      return 'Desistência'
    case SessionStatus.CANCELED_CHARGED:
      return 'Cancelado Sem Antecedência'
    case SessionStatus.CANCELED_DISPLACEMENT:
      return 'Cancelado Com Deslocamento'
  }
}
