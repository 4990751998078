import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Icon } from 'shared/ui/icon'

import classes from './NavbarLinks.module.scss'

interface Props {
  auth: Model.User
}

export const NavbarLinks: React.FC<Props> = ({ auth }) => {
  const handleLogout = (event: React.MouseEvent) => {
    event.preventDefault()
    fetch(`/api/auth/logout`).then(response => {
      if (response.ok) {
        window.location.reload()
      } else {
        alert('Falha ao sair da aplicação')
      }
    })
  }
  return (
    <Fragment>
      <div className={classes.list}>
        <Link className={classes.link} to="/sessions">
          Ensaios
        </Link>
        <Link className={classes.link} to="/clients">
          Clientes
        </Link>
        <Link className={classes.link} to="/providers">
          Fornecedores
        </Link>
        <Link className={classes.link} to="/deliverables">
          Entregáveis
        </Link>
        <Link className={classes.link} to="/matterport/tours">
          Matterport
        </Link>
        {auth.permissions.finantial && (
          <Link className={classes.link} to="/finantial">
            Financeiro
          </Link>
        )}
        {/*<Link className={classes.link} to="/cities">
          Cidades
        </Link>*/}
      </div>
      <p className={classes.user}>
        <Icon.User /> {auth.name}
      </p>
      <button className={classes.button} onClick={handleLogout}>
        Sair <Icon.SignInAlt />
      </button>
    </Fragment>
  )
}
