import React, { Fragment, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button } from 'components/Button'
import { FormCheck } from 'shared/form/FormCheck'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/ui/card/Card'
import { CardGrey } from 'shared/ui/card/CardGrey'
import { Container } from 'shared/ui/container/Container'

import classes from './ProviderAdd.module.scss'

export const ProviderAdd: React.FC<RouteComponentProps> = ({ history }) => {
  // user data
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState<any>()
  const [RG, setRG] = useState('')
  const [CPF, setCPF] = useState('')
  const [password, setPassword] = useState('')

  const [residence, setResidence] = useState(false)
  const [identity, setIdentity] = useState(false)

  const onNameChange = (name: string) => setName(name)
  const onPhoneChange = (phone: string) => setPhone(phone)
  const onEmailChange = (email: string) => setEmail(email)

  const onRGChange = (RG: string) => setRG(RG)
  const onCPFChange = (CPF: string) => setCPF(CPF)
  const onPasswordChange = (password: string) => setPassword(password)

  const onResidenceChange = (residence: boolean) => setResidence(residence)
  const onIdentityChange = (identity: boolean) => setIdentity(identity)

  const addressRef = useRef<HTMLInputElement>(null)

  const autocompleteRef = useRef<google.maps.places.Autocomplete>()
  useEffect(() => {
    if (addressRef.current && !Boolean(autocompleteRef.current)) {
      autocompleteRef.current = new google.maps.places.Autocomplete(addressRef.current, {
        componentRestrictions: {
          country: ['BR']
        },
        types: ['address']
      })
      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current?.getPlace()
        if (place) {
          setAddress(place)
          addressRef.current!.value = place.formatted_address!
        }
      })
    }
  }, [])

  // save form
  const save = (event: React.FormEvent) => {
    event.preventDefault()

    let body = {
      user: {
        name,
        phone,
        email,
        cpf: CPF,
        password
      },
      name,
      address,
      residence,
      identity,
      rg: RG
    }

    fetch(`/api/providers`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' })
    })
      .then(res => {
        if (res.ok) {
          return res.json()
        }
      })
      .then(({ provider }: any) => history.push(`/providers/${provider.id}`))
      .catch(err => console.error(err))
  }

  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem>Fornecedores</BreadcrumbItem>
        <BreadcrumbItem>Criar fornecedor</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <form onSubmit={save}>
          <Container>
            <h1 className={classes.title}>Criar fornecedor</h1>
            <CardGrey className={classes.card}>
              <p className={classes.legend}>Dados do fornecedor</p>
              <div className={classes.form}>
                <FormGroup className={classes.expand} label="Nome*">
                  <FormText required onChange={onNameChange} />
                </FormGroup>
                <FormGroup label="Telefone*">
                  <FormMasked required mask="phone" onChange={onPhoneChange} />
                </FormGroup>
                <FormGroup label="E-mail*">
                  <FormText required onChange={onEmailChange} />
                </FormGroup>
                <FormGroup className={classes.expand} label="Endereço">
                  <FormText
                    placeholder="Pesquisar endereço"
                    ref={addressRef}
                    required
                    value={address?.formatted_address}
                  />
                </FormGroup>
                <FormGroup label="RG">
                  <FormText onChange={onRGChange} />
                </FormGroup>
                <FormGroup label="CPF">
                  <FormMasked mask="cpf" onChange={onCPFChange} />
                </FormGroup>
                <FormGroup label="Senha (opcional)">
                  <FormText onChange={onPasswordChange} />
                </FormGroup>
                <FormCheck className={classes.expand} onChange={onResidenceChange}>
                  Comprovante de residência
                </FormCheck>
                <FormCheck className={classes.expand} onChange={onIdentityChange}>
                  Foto da identidade
                </FormCheck>
              </div>
            </CardGrey>
            <div className={classes.bottom}>
              <Button outline type="button" onClick={history.goBack}>
                VOLTAR
              </Button>
              <Button>SALVAR</Button>
            </div>
          </Container>
        </form>
      </Card>
    </Fragment>
  )
}
