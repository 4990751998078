export enum UserProfile {
  LegalRepresentative = 1 << 1,
  FinancesManager = 1 << 2,
  OperationManager = 1 << 3

  // VIEW_FINANTIAL = 1n << 32n
}

export function getUserProfileDescription(profile: UserProfile) {
  switch (profile) {
    case UserProfile.LegalRepresentative:
      return 'Representante Legal'
    case UserProfile.FinancesManager:
      return 'Responsável Financeiro'
    case UserProfile.OperationManager:
      return 'Responsável Operacional'
  }
}
