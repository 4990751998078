import React, { useCallback, useState } from 'react'

import { useSessions } from 'shared/hooks/data/session'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator } from 'shared/ui/table'

import { ReportTableFilter } from './ReportTableFilter'
import { ReportTableRow } from './ReportTableRow'

import classes from './ReportTable.module.scss'

interface SessionListTableProps {
  className?: string
  params?: {
    filter?: { [key: string]: any }
  }
}

/**
 *
 * ID do Ensaio	 => ABRE O ENSAIO
 * ID REF CLIENTE
 * CLIENTE
 * DATA ENSAIO
 * DATA CANCEL
 * ÁREA M² => METRAGEM GLA OU INFORMADO PELO CLIENTE
 * PROJEÇÃO HOST => MESES DE HOSPEDAGEM
 * STATUS
 * SERVIÇOS
 * PROVIDERS
 * CUSTO PLANTA	=> EDITÁVEL
 * VALOR SERVIÇO => EDITÁVEL
 * CUSTO PROVEIDERS => EDITÁVEL
 * CUSTO VÍDEO => EDITÁVEL
 * OUTROS CUSTOS => EDITÁVEL
 * SLA => CRIAR CAMPO NO SERVIÇO
 * M² Excedente	=> CRIAR CAMPO NO SERVIÇO
 * ENDEREÇO
 * Competência mês => MÊS EM QUE OCORREU A CAPTAÇÃO DAS IMAGENS, OU QUE O ENSAIO FOI AGENDADO
 * Custo total do Ensaio => SOMA DOS CUSTOS
 *
 *
 * - alteração do provider na tabela
 * - acima de 150 m2 mostrar um marcador na coluna de área
 */

export const ReportTable: React.FC<SessionListTableProps> = ({ className, params }) => {
  const [current, setCurrent] = useState({ filter: { ...params?.filter, scope: 'finantial' } })
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 100 })
  // const [selectedAll, setSelectedAll] = useState(false)
  // const [selected, setSelected] = useState(new Set<number>())
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const sessions = useSessions(current, paging, handleMeta)
  const handleFilter = useCallback((filter: object) => {
    setCurrent({ filter: { ...filter, scope: 'finantial' } })
  }, [])
  return (
    <div className={classes.container}>
      <ReportTableFilter onChange={handleFilter} />
      <Table className={className} dense hover strip>
        <TableHeader>
          <TableColumn align="center" className={classes.id}>
            Ensaio
          </TableColumn>
          <TableColumn align="center" className={classes.client}>
            Cliente
          </TableColumn>
          <TableColumn align="center" className={classes.dates}>
            Datas
          </TableColumn>
          <TableColumn align="center" className={classes.area}>
            Área
          </TableColumn>
          <TableColumn align="center" className={classes.host}>
            Projeção Host
          </TableColumn>
          <TableColumn align="center" className={classes.products}>
            Serviço
          </TableColumn>
          <TableColumn align="center" className={classes.price}>
            Valor Serviço
          </TableColumn>
          <TableColumn align="center" className={classes.deliverables}>
            Fornecedores
          </TableColumn>
          <TableColumn align="center" className={classes.costs}>
            Custos
          </TableColumn>
          <TableColumn align="center" className={classes.sla}>
            SLA
          </TableColumn>
          <TableColumn align="center" className={classes.address}>
            Endereço
          </TableColumn>
          <TableColumn align="center" className={classes.month}>
            Competência Mês
          </TableColumn>
          {/*<TableColumn align="center">Custo Total</TableColumn>*/}
        </TableHeader>
        <TableBody>
          {sessions?.map(session => (
            <ReportTableRow key={session.id} session={session} />
          ))}
        </TableBody>
        <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
      </Table>
    </div>
  )
}
