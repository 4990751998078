import { useEffect, useState } from 'react'

export function useProviders(data: any) {
  const [providers, setProviders] = useState<any[]>()
  useEffect(() => {
    const params = new URLSearchParams()
    if (data && 'item[id]' in data) {
      params.set('item[id]', data['item[id]'])
    }
    if (data && 'item[pivot][deliverable]' in data) {
      params.set('item[pivot][deliverable]', data['item[pivot][deliverable]'])
    }
    // Object.entries(data).map(([key, value]) => {
    //   params.set(key, `${value}`)
    // })
    fetch(`/api/providers?${params.toString()}`)
      .then(response => response.json())
      .then(({ data }) => setProviders(data))
  }, [data])
  return providers
}

export function useProvider(key: ModelKey) {
  const [provider, setProvider] = useState<any>()
  useEffect(() => {
    fetch(`/api/providers/${key.id}`)
      .then(response => response.json())
      .then(({ data }) => setProvider(data))
  }, [key])
  return [provider, setProvider] as ReactState<any>
}
