import React, { useState } from 'react'

import { SessionStatus } from 'shared/enum/session/SessionStatus'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card } from 'shared/bootstrap/card'
import { Container } from 'shared/ui/container/Container'

import { SessionListTable } from './components/SessionListTable'
import { TourListTable } from './components/TourListTable'

import classes from './TourList.module.scss'

export const TourList: React.FC = () => {
  const [session, setSession] = useState({
    filter: {
      scopes: ['tourNotAssociated'],
      status: SessionStatus.PENDING
    }
  })
  const [tour, setTour] = useState({
    filter: { status: 1 }
  })
  const handleChange = () => {
    setSession(session => ({ ...session }))
    setTour(tour => ({ ...tour }))
  }
  return (
    <Container>
      <Breadcrumb className={classes.header}>
        <BreadcrumbItem to="/matterport">Matterport</BreadcrumbItem>
        <BreadcrumbItem>Tours</BreadcrumbItem>
      </Breadcrumb>
      {/*<Card className={classes.card} shadow>
        <CardBody>{<DeliverableListFilter onChange={handleFilter} />}</CardBody>
      </Card>*/}
      <div className={classes.content}>
        <Card className={classes.card} shadow>
          <TourListTable filter={tour} onChange={handleChange} />
        </Card>
        <Card className={classes.card} shadow>
          <SessionListTable filter={session} />
        </Card>
      </div>
    </Container>
  )
}
