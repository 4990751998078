import { useEffect, useState } from 'react'

export function useServices() {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/services`)
      .then(response => response.json())
      .then(({ data }) => setServices(data))
  }, [])
  return services
}

export function useClientServices(clientId?: number | undefined) {
  const [services, setServices] = useState<any[]>()
  useEffect(() => {
    fetch(`/api/client/services`)
      .then(response => response.json())
      .then(({ data }) => setServices(data))
  }, [clientId])
  return services
}

export function useService(id?: number) {
  const [service, setService] = useState<Model.Service>()
  useEffect(() => {
    if (id) {
      fetch(`/api/services/${id}`)
        .then(response => response.json())
        .then(({ data }) => setService(data))
    } else {
      setService(undefined)
    }
  }, [id])
  return service
}
