import React from 'react'

import { Button } from 'components/Button'
import { FormInputGroup, FormInputGroupAppend } from 'shared/form/FormInputGroup'
import { FormText } from 'shared/form/FormText'

export const Scheduling: App.SessionAddSection = ({ state: [sessions] }) => {
  return (
    <form>
      <h1>Agenda</h1>
      <section>
        {sessions.map(({ token, vertical_data: { area, complement, reference, unit } }, i) => (
          <div key={i}>
            <h3>Ensaio {i + 1}</h3>
            <p>
              Ref: {reference} - Unidade {unit}/{complement} - {area}m<sup>2</sup>
            </p>
            <FormInputGroup>
              <FormText readOnly value={`https://my.piperz.io/calendar/${token}`} />
              <FormInputGroupAppend>
                <Button href={`https://my.piperz.io/calendar/${token}`}>Abrir</Button>
              </FormInputGroupAppend>
            </FormInputGroup>
          </div>
        ))}
      </section>
    </form>
  )
}

/*
export const Scheduling: App.SessionAddSection = ({state: [sessions, setSessions]}) => { // ({state: [sessions, setSessions]}) => {
  return (
    <form>
      <h1>Agenda</h1>
      <section>
        <h3>Envie o link para o cliente agendar:</h3>
        <FormText required />
        <h3>Ou agende agora:</h3>
        Calendário
      </section>
      <Button size="lg">
        AVANÇAR →
      </Button>
    </form>
  )
}*/
