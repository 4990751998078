import React, { Fragment, useEffect, useState } from 'react'

import { Icon } from 'shared/ui/icon'
import { FormCheck } from 'shared/form/FormCheck'
import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormSelect } from 'shared/form/FormSelect'
import { FormText } from 'shared/form/FormText'
import { InputAddress } from 'shared/form/InputAddress'

import classes from './ProviderInfo.module.scss'
import { useBanks } from 'hooks/data/banks'
import { Button } from 'components/Button'
import { FormLogo } from 'shared/form/FormLogo'

export const ProviderInfo: React.FC = ({ state }: any) => {
  let [provider, setProvider] = state
  const banks = useBanks()!
  const [saved, setSaved] = useState(true)
  const [saved2, setSaved2] = useState(true)

  useEffect(() => {
    if (!provider.bank_account) {
      provider.bank_account = {}
    }
  }, [provider])

  const updateFoto = ([file]: File[]) => {
    setSaved(false)
    const body = new FormData()
    body.set('user[profile_image]', file)

    fetch(`/api/providers/${provider.id}`, {
      method: 'POST',
      body
    })
      .then(res => res.json())
      .then(({ data }: any) => setProvider(data))
      .then(() => setSaved(true))
  }

  const save = (saveWho: any) => {
    saveWho(false)
    let body = {
      user: provider.user,
      bank: provider.bank_account,
      residence: provider.residence,
      identity: provider.identity,
      name: provider.name,
      address: provider.address,
      rg: provider.rg,
      cnpj: provider.cnpj
    }

    fetch(`/api/providers/${provider.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...body })
    })
      .then(res => res.json())
      .then(({ data }: any) => setProvider(data))
      .then(() => saveWho(true))
  }

  const onClick = () => save(setSaved)
  const onClick2 = () => save(setSaved2)

  return (
    <Fragment>
      <div className={classes.card}>
        <h2>Dados do Fornecedor</h2>
        <div className={classes.form}>
          <FormGroup>
            {/* <div className={classes.thumbnail}>
              <img src={provider?.user?.profile_image_path} />
            </div> */}
            <FormLogo accept=".jpg,.jpeg,.png,.svg" onChange={updateFoto} value={provider?.user?.profile_image_path} />
          </FormGroup>
          <FormGroup className={classes.expand} label="Nome">
            <FormText value={provider?.user?.name} onChange={name => (provider.user.name = name)} />
          </FormGroup>
          <FormGroup label="Telefone">
            <FormMasked mask="phone" value={provider?.user?.phone} onChange={phone => (provider.user.phone = phone)} />
          </FormGroup>
          <FormGroup label="E-mail">
            <FormText value={provider?.user?.email} onChange={email => (provider.user.email = email)} />
          </FormGroup>
          <FormGroup className={classes.expand} label="Endereço">
            <InputAddress initial={provider?.address} onChange={address => (provider.address = address)} />
          </FormGroup>
          <FormGroup label="RG">
            <FormText value={provider?.rg} onChange={rg => (provider.rg = rg)} />
          </FormGroup>
          <FormGroup label="CPF">
            <FormMasked mask="cpf" value={provider?.user?.cpf} onChange={cpf => (provider.user.cpf = cpf)} />
          </FormGroup>
          <FormGroup label="Senha?">
            <FormText placeholder={''} />
          </FormGroup>
          <FormGroup className={classes.center}>
            <FormCheck
              className={classes.expand}
              checked={(provider.information & 1) !== 0}
              onChange={residence => (provider.residence = residence)}>
              Comprovante de residência
            </FormCheck>
            <FormCheck
              className={classes.expand}
              checked={(provider.information & 2) !== 0}
              onChange={identity => (provider.identity = identity)}>
              Foto da identidade
            </FormCheck>
          </FormGroup>
        </div>
        <div className={classes.row}>
          <Button onClick={onClick}>Salvar</Button>
          <div className={classes.check}>{saved ? <Icon.CheckCircle /> : <Icon.CircleNotch spin />}</div>
        </div>
      </div>
      <div className={classes.card}>
        <div className={classes.secondform}>
          <div>
            <h2>Dados da empresa</h2>
            <div className={classes.columnform}>
              <FormGroup label="Razão Social">
                <FormText value={provider?.name} onChange={name => (provider.name = name)} />
              </FormGroup>
              <FormGroup label="CNPJ">
                <FormMasked mask="cnpj" value={provider?.cnpj} onChange={cnpj => (provider.cnpj = cnpj)} />
              </FormGroup>
            </div>
          </div>
          <div className={classes.bank}>
            <h2>Dados bancários</h2>
            <div className={classes.bankform}>
              <FormGroup label="Banco">
                <FormSelect
                  value={provider?.bank_account?.bank_id}
                  onChange={bank_id => (provider.bank_account.bank_id = bank_id)}>
                  {banks?.map((bank, index) => (
                    <option key={index} value={index}>
                      {bank.code} - {bank.name}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
              <FormGroup label="Nome">
                <FormText
                  value={provider?.bank_account?.owner_name}
                  onChange={owner_name => (provider.bank_account.owner_name = owner_name)}
                />
              </FormGroup>
              <FormGroup label="CPF/CNPJ">
                <FormMasked
                  mask="cnpj-cpf"
                  value={provider?.bank_account?.identification}
                  onChange={identification => (provider.bank_account.identification = identification)}
                />
              </FormGroup>
              <FormGroup label="Agência">
                <FormText
                  value={provider?.bank_account?.agency}
                  onChange={agency => (provider.bank_account.agency = agency)}
                />
              </FormGroup>
              <FormGroup label="Conta">
                <FormText
                  value={provider?.bank_account?.account}
                  onChange={account => (provider.bank_account.account = account)}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <Button onClick={onClick2}>Salvar</Button>
          <div className={classes.check}>{saved2 ? <Icon.CheckCircle /> : <Icon.CircleNotch spin />}</div>
        </div>
      </div>
    </Fragment>
  )
}
