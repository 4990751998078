import React, { useContext, useEffect, useState } from 'react'

type AuthContextType = ReactState<Optional<Model.User>>

const AuthContext = React.createContext<Nullable<AuthContextType>>(null)

export function useAuthContext() {
  return useContext(AuthContext)!
}

export const AuthContextProvider: React.FC = props => {
  const [user, setUser] = useState<Optional<Model.User>>()
  useEffect(() => {
    fetch(`/api/auth/user`)
      .then(response => {
        if (response.ok) {
          response.json().then(({ data }) => {
            if (data?.scope !== 'admin') {
              return fetch(`/api/auth/logout`, { method: 'POST' }).then(() => setUser(null))
            }
            setUser(data)
          })
        } else {
          throw new Error()
        }
      })
      .catch(() => setUser(null))
  }, [])
  return <AuthContext.Provider value={[user, setUser]} {...props} />
}
