import React, { useRef, useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { DropdownMenu } from 'shared/bootstrap/dropdown/DropdownMenu'
import { classNames } from 'shared/util/class-names'

import { InputDropdownContextProvider } from './InputDropdownContext'

interface Props {
  defaultText?: string
  end?: boolean
  onChange?: (_: number | string) => void
  toggleClass?: string
}

export const InputDropdown: React.FC<Props> = ({ children, defaultText, onChange, toggleClass }) => {
  const [displayText, setDisplayText] = useState(defaultText || '')
  const [opened, setOpened] = useState(false)
  const context = useRef({
    items: new Map(),
    onClick: ([key, value]: [number | string, string]) => {
      setDisplayText(value)
      onChange?.(key)
    }
  })
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    setOpened(opened => !opened)
  }
  return (
    <InputDropdownContextProvider value={context.current}>
      <Button className={classNames('dropdown-toggle', toggleClass)} onClick={handleClick} outline variant="secondary">
        {displayText}
      </Button>
      <DropdownMenu opened={opened}>{children}</DropdownMenu>
    </InputDropdownContextProvider>
  )
}
