import React, { useEffect, useState } from 'react'
import { isValid, parseISO } from 'date-fns'

import { Button } from 'shared/bootstrap/button'
import { Input } from 'shared/form/Input'
import { InputDateRange } from 'shared/form/InputDateRange'
import { InputDropdown } from 'shared/form/InputDropdown'
import { InputDropdownItem } from 'shared/form/InputDropdownItem'
import { Icon } from 'shared/ui/icon'

import classes from './ReportTableFilter.module.scss'

interface Props {
  onChange?: (_: any) => void
}

enum FILTER_TYPE {
  ALL,
  CLIENT,
  PROVIDER,
  DELIVERABLE,
  STATUS,
  ADDRESS,
  ID,
  REFERENCE,
  SCHEDULED_FOR,
  CREATED_AT,
  DELIVERED_AT,
  CANCELED_AT
}

function filterTypeDescription(type: FILTER_TYPE) {
  switch (type) {
    case FILTER_TYPE.ALL:
      return 'Todos'
    case FILTER_TYPE.CLIENT:
      return 'Cliente'
    case FILTER_TYPE.PROVIDER:
      return 'Fornecedor'
    case FILTER_TYPE.DELIVERABLE:
      return 'Entregável'
    case FILTER_TYPE.STATUS:
      return 'Status do Ensaio'
    case FILTER_TYPE.ADDRESS:
      return 'Endereço'
    case FILTER_TYPE.ID:
      return 'ID'
    case FILTER_TYPE.REFERENCE:
      return 'Referência'
    case FILTER_TYPE.SCHEDULED_FOR:
      return 'Ensaio'
    case FILTER_TYPE.CREATED_AT:
      return 'Criação'
    case FILTER_TYPE.DELIVERED_AT:
      return 'Entrega'
    case FILTER_TYPE.CANCELED_AT:
      return 'Cancelamento'
  }
}

export const ReportTableFilter: React.FC<Props> = ({ onChange }) => {
  const [date, setDate] = useState<[string, string]>()
  const [dateFilter, setDateFilter] = useState<number | string>(FILTER_TYPE.SCHEDULED_FOR)
  const [input, setInput] = useState('')
  const [inputFilter, setInputFilter] = useState<number | string>(FILTER_TYPE.ALL)
  const [filters, setFilters] = useState<[FILTER_TYPE, number | string | [string, string]][]>([])
  const handleRemove = (i: number) => (event: React.MouseEvent) => {
    event.preventDefault()
    setFilters(filters => filters.filter((_, index) => i !== index))
  }
  const handleDate = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (date) {
      const type = dateFilter as FILTER_TYPE
      const allowed = [
        FILTER_TYPE.SCHEDULED_FOR,
        FILTER_TYPE.CREATED_AT,
        FILTER_TYPE.DELIVERED_AT,
        FILTER_TYPE.CANCELED_AT
      ]
      if (allowed.indexOf(type) >= 0) {
        setFilters(filters => [...filters, [type, date]])
      }
    }
  }
  const handleInput = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const type = inputFilter as FILTER_TYPE
    setFilters(filters => [...filters, [type, input]])
  }
  useEffect(() => {
    const filter = filters.reduce((current: Record<string, any>, [type, value]) => {
      switch (type) {
        case FILTER_TYPE.ALL:
          current['query'] = value
          break
        case FILTER_TYPE.CLIENT:
          current['client_name'] = value
          break
        case FILTER_TYPE.PROVIDER:
          current['provider_name'] = value
          break
        case FILTER_TYPE.DELIVERABLE:
          current['item_name'] = value
          break
        case FILTER_TYPE.STATUS:
          current['status_name'] = value
          break
        case FILTER_TYPE.ADDRESS:
          current['address'] = value
          break
        case FILTER_TYPE.ID:
          current['id'] = value
          break
        case FILTER_TYPE.REFERENCE:
          current['reference'] = value
          break
        case FILTER_TYPE.SCHEDULED_FOR:
          current['scheduled_for'] = value
          break
        case FILTER_TYPE.CREATED_AT:
          current['created_at'] = value
          break
        case FILTER_TYPE.DELIVERED_AT:
          current['delivered_at'] = value
          break
        case FILTER_TYPE.CANCELED_AT:
          current['canceled_at'] = value
          break
      }
      return current
    }, {})
    onChange?.(filter)
  }, [filters, onChange])
  return (
    <div className={classes.container}>
      <div className={classes.dropdown}>
        <InputDropdown onChange={setInputFilter} toggleClass={classes.toggle} defaultText="Todos">
          <InputDropdownItem value={FILTER_TYPE.ALL}>Todos</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.CLIENT}>Cliente</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.PROVIDER}>Fornecedor</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.DELIVERABLE}>Entregável</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.STATUS}>Status do Ensaio</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.ADDRESS}>Endereço</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.ID}>ID</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.REFERENCE}>Referência</InputDropdownItem>
        </InputDropdown>
      </div>
      <Input className={classes.input} placeholder="Pesquisar..." onChange={setInput} />
      <Button className={classes.confirm} variant="secondary" disabled={input.length < 3} onClick={handleInput}>
        OK
      </Button>
      <div className={classes.dropdown}>
        <InputDropdown onChange={setDateFilter} toggleClass={classes.toggle} defaultText="Ensaio">
          <InputDropdownItem value={FILTER_TYPE.SCHEDULED_FOR}>Ensaio</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.CREATED_AT}>Criação</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.DELIVERED_AT}>Entrega</InputDropdownItem>
          <InputDropdownItem value={FILTER_TYPE.CANCELED_AT}>Cancelamento</InputDropdownItem>
        </InputDropdown>
      </div>
      <InputDateRange onChange={setDate} />
      <Button
        className={classes.confirm}
        variant="secondary"
        disabled={!isValid(parseISO(date?.[0] || '')) || !isValid(parseISO(date?.[1] || ''))}
        onClick={handleDate}>
        OK
      </Button>
      <div className={classes.filters}>
        {filters.map(([filter, value], i) => (
          <div className={classes.filter}>
            {Array.isArray(value) ? (
              <span>
                {filterTypeDescription(filter)}: {value.join(' - ')}
              </span>
            ) : (
              <span>
                {filterTypeDescription(filter)}: {value}
              </span>
            )}
            <button className={classes.remove} onClick={handleRemove(i)}>
              <Icon.Times />
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
