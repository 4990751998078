import React, { useState } from 'react'
import { isPast, isValid, parseISO, subHours } from 'date-fns'

import { FormCheck } from 'shared/form/FormCheck'
import { Modal } from 'shared/modal'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import classes from './CancelSessionModal.module.scss'

interface CancelSessionModalProps {
  category: 'admin' | 'client' | 'provider'
  close: () => void
  onChange: () => void
  session: Resource.SessionResource
}

export const CancelSessionModal: React.FC<CancelSessionModalProps> = ({
  category = 'admin',
  close,
  onChange,
  session
}) => {
  const admin = category === 'admin'
  const scheduledFor = session.scheduled_for && parseISO(session.scheduled_for)
  const isFeeApplicable = isValid(scheduledFor) && isPast(subHours(scheduledFor as Date, 24))
  const [hasFee, setHasFee] = useState(isFeeApplicable)
  const confirm = () => {
    Promise.resolve()
      .then(() => {
        if (category === 'admin') {
          return toFormData({
            has_fee: hasFee
          })
        } else {
          return new FormData()
        }
      })
      .then(body => fetch(`/api/sessions/cancel/${session.id}`, { body, method: 'POST' }))
      .then(onChange)
      .then(close)
      .catch(() => alert('Falha ao cancelar ensaio. Por favor, contate nosso suporte.'))
  }

  return (
    <Modal size="sm">
      <div className={classes.title}>
        <h3>Cancelar ensaio</h3>
      </div>
      <div className={classes.divider} />
      <div className={classes.description}>
        <h4>Confirma o cancelamento do ensaio ID {session.id}?</h4>
        {isFeeApplicable && (
          <p>Cancelamentos realizados no período das 24h anteriores ao agendamento da sessão possuem taxa de 30%.</p>
        )}
        {admin && (
          <FormCheck onChange={setHasFee} checked={hasFee}>
            Efetuar cobrança da taxa de 30%.
          </FormCheck>
        )}
      </div>
      <div className={classes.divider} />
      <div className={classes.buttons}>
        <Button outline onClick={close}>
          Cancelar
        </Button>
        <Button onClick={confirm}>Confirmar</Button>
      </div>
    </Modal>
  )
}
