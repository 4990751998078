import React, { useRef /*, useState*/ } from 'react'

import classes from './InputCheck.module.scss'

interface Props {
  onChange?: (_: boolean) => void
  checked?: boolean
  value?: string
}

let uniqueId = 0

export const InputCheck: React.FC<Props> = ({ children, onChange, ...props }) => {
  const id = useRef(`input-check-${++uniqueId}`)
  const input = {
    id: id.current,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked)
    },
    type: 'checkbox'
  }
  return (
    <div className={`form-check ${classes.check}`}>
      <input className="form-check-input" {...input} {...props} />
      <label className="form-check-label" htmlFor={id.current}>
        {children}
      </label>
    </div>
  )
}
