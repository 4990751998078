import React, { useRef, useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { ItemWorkType } from 'shared/enum/item'
import { SessionStatus, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { Input } from 'shared/form/Input'
import { InputAddress } from 'shared/form/InputAddress'
import { InputDateTime } from 'shared/form/InputDateTime'
import { InputDecimal } from 'shared/form/InputDecimal'
import { InputSelect } from 'shared/form/InputSelect'
import { InputSelectItem } from 'shared/form/InputSelectItem'
import { InputTextArea } from 'shared/form/InputTextArea'
import { FormGroup } from 'shared/form/FormGroupNew'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import { useProviders } from 'hooks/data/providers'

import classes from './SessionEdit.module.scss'

interface SessionEditProps {
  session: Resource.SessionResource
}

const sessionStatus = [
  SessionStatus.NOT_SCHEDULED,
  SessionStatus.SCHEDULED,
  SessionStatus.CANCELED_NOT_CHARGED,
  SessionStatus.CANCELED_CHARGED,
  SessionStatus.CANCELED_DISPLACEMENT,
  SessionStatus.PENDING,
  SessionStatus.DELIVERED
]

export const SessionEdit: React.FC<SessionEditProps> = ({ session }) => {
  const itemIdRef = useRef({
    'item[id]': session.products.find(({ work_type }) => work_type === ItemWorkType.LOCAL)?.item.id || '',
    'item[pivot][deliverable]': 1
  })
  const providers = useProviders(itemIdRef.current)
  const [value, setValue] = useState<Partial<Model.Session>>({})
  const handleChange = (key: string) => (value: object | number | string) => {
    setValue(v => ({ ...v, [key]: value }))
  }
  const handleAdditionalInfo = (key: string) => (value: string) => {
    setValue(({ additional_info = {}, ...v }) => ({
      ...v,
      additional_info: { ...additional_info, [key]: value }
    }))
  }
  const handleProviderInformation = (key: string) => (value: number | string) => {
    setValue(({ provider_information = {}, ...v }) => ({
      ...v,
      provider_information: { ...provider_information, [key]: value }
    }))
  }
  const handleResponsibleInfo = (key: string) => (value: number | string) => {
    setValue(({ responsible_information = {}, ...v }) => ({
      ...v,
      responsible_information: { ...responsible_information, [key]: value }
    }))
  }
  const handleSchedulingResponsibleInfo = (key: string) => (value: number | string) => {
    setValue(({ scheduling_responsible_info = {}, ...v }) => ({
      ...v,
      scheduling_responsible_info: { ...scheduling_responsible_info, [key]: value }
    }))
  }
  const handleVerticalData = (key: string) => (value: number | string) => {
    setValue(({ vertical_data = {}, ...v }) => ({
      ...v,
      vertical_data: { ...vertical_data, [key]: value }
    }))
  }
  const handleSave = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData(value)
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw Error()
        }
      })
      .catch(() => alert('Falha ao salvar ensaio!'))
  }
  return (
    <Modal size="xl">
      <ModalHeader title="Alterar informações do ensaio" />
      <ModalBody className={classes.body}>
        <div className={classes.grid}>
          <FormGroup className={classes.address} label="Endereço completo">
            <InputAddress onChange={handleChange('address')} initial={session.address} />
          </FormGroup>
          <FormGroup className={classes.checklistLink} label="Link de check-list">
            <Input
              type="url"
              onChange={handleAdditionalInfo('checklist_link')}
              initial={session.additional_info?.checklist_link}
            />
          </FormGroup>
          <FormGroup className={classes.deliveryLink} label="Link de entrega">
            <Input
              type="url"
              onChange={handleAdditionalInfo('delivery_link')}
              initial={session.additional_info?.delivery_link}
            />
          </FormGroup>
          <FormGroup className={classes.additional} label="Informações adicionais">
            <InputTextArea
              onChange={handleVerticalData('additional_info')}
              initial={session.vertical_data?.additional_info}
            />
          </FormGroup>
          <div className={classes.responsibles}>
            <div className={classes.responsible}>
              <h6>Responsável pelo ensaio</h6>
              <FormGroup label="Nome">
                <Input onChange={handleResponsibleInfo('name')} initial={session.responsible_information.name} />
              </FormGroup>
              <FormGroup label="E-mail">
                <Input onChange={handleResponsibleInfo('email')} initial={session.responsible_information.email} />
              </FormGroup>
              <FormGroup label="Telefone">
                <Input onChange={handleResponsibleInfo('phone')} initial={session.responsible_information.phone} />
              </FormGroup>
            </div>
            <div className={classes.responsible}>
              <h6>Quem receberá o fotógrafo</h6>
              <FormGroup label="Nome">
                <Input
                  onChange={handleSchedulingResponsibleInfo('name')}
                  initial={session.scheduling_responsible_info.name}
                />
              </FormGroup>
              <FormGroup label="E-mail">
                <Input
                  onChange={handleSchedulingResponsibleInfo('email')}
                  initial={session.scheduling_responsible_info.email}
                />
              </FormGroup>
              <FormGroup label="Telefone">
                <Input
                  onChange={handleSchedulingResponsibleInfo('phone')}
                  initial={session.scheduling_responsible_info.phone}
                />
              </FormGroup>
            </div>
          </div>
          <FormGroup className={classes.scheduledFor} label="Agendamento">
            <InputDateTime onChange={handleChange('scheduled_for')} initial={session.scheduled_for} />
          </FormGroup>
          <FormGroup className={classes.status} label="Status">
            <InputSelect onChange={handleChange('status')} value={getSessionStatusDescription(session.status)}>
              {sessionStatus.map(status => (
                <InputSelectItem key={status} value={status}>
                  {getSessionStatusDescription(status)}
                </InputSelectItem>
              ))}
            </InputSelect>
          </FormGroup>
          <FormGroup className={classes.provider} label="Fotógrafo">
            <InputSelect onChange={handleChange('provider_id')} value={session.provider?.name}>
              {providers?.map(provider => (
                <InputSelectItem key={provider.id} value={provider.id}>
                  {provider.name}
                </InputSelectItem>
              ))}
            </InputSelect>
          </FormGroup>
          <div className={classes.vertical}>
            <FormGroup label="Unidade">
              <Input onChange={handleVerticalData('unit')} initial={session.vertical_data?.unit} />
            </FormGroup>
            <FormGroup label="Bloco/Torre/Quadra">
              <Input onChange={handleVerticalData('complement')} initial={session.vertical_data?.complement} />
            </FormGroup>
            <FormGroup label="Área">
              <InputDecimal onChange={handleVerticalData('area')} scale={2} initial={session.vertical_data?.area} />
            </FormGroup>
            <FormGroup label="Referência">
              <Input onChange={handleVerticalData('reference')} initial={session.vertical_data?.reference} />
            </FormGroup>
            <FormGroup className={classes.keys} label="Chaves">
              <Input onChange={handleVerticalData('keys')} initial={session.vertical_data?.keys} />
            </FormGroup>
            <FormGroup className={classes.providerInfo} label="Informações extras do agendamento">
              <InputTextArea onChange={handleProviderInformation('extra')} initial={session.provider_info?.extra} />
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave} size="sm">
          SALVAR
        </Button>
      </ModalFooter>
    </Modal>
  )
}
