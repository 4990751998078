import React, { useCallback, useState } from 'react'

import { useMatterportTours } from 'shared/hooks/data/matterport-tour'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator } from 'shared/ui/table'
// import { formatISO } from 'shared/util/date'
// import { clipboardWrite } from 'shared/util/functions'
// import { parseAddress } from 'shared/util/google-maps'

import { TourListTableItem } from './TourListTableItem'

// import classes from './TourListTable.module.scss'

interface Props {
  className?: string
  filter?: { [key: string]: any }
  onChange?: () => void
}

export const TourListTable: React.FC<Props> = ({ filter, onChange, ...props }) => {
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const tours = useMatterportTours(filter, paging, handleMeta)
  return (
    <Table hover strip {...props}>
      <TableHeader>
        <TableColumn align="center">ID</TableColumn>
        <TableColumn size={3}>Tour</TableColumn>
        <TableColumn size={2}>Ensaio</TableColumn>
      </TableHeader>
      <TableBody>
        {tours?.map(tour => (
          <TourListTableItem key={tour.id} onChange={onChange} tour={tour} />
        ))}
      </TableBody>
      <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
    </Table>
  )
}
