import React, { useEffect, useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { Input } from 'shared/form/Input'
import { InputAutocomplete } from 'shared/form/InputAutocomplete'
import { FormGroup } from 'shared/form/FormGroupNew'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon'
import { toFormData } from 'shared/util/form-data'
import { useService } from 'shared/hooks/data/service'
import { ServiceDataSource } from 'shared/hooks/source/ServiceDataSource'

import classes from './SessionServiceChangeModal.module.scss'

interface Props {
  session: Resource.SessionResource
}

export const SessionServiceChangeModal: React.FC<Props> = ({ session }) => {
  const [error, setError] = useState('')
  const [serviceId, setServiceId] = useState<number | string>()
  const service = useService(serviceId as number)
  const handleSave = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ service_id: serviceId })
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw Error()
        }
      })
      .catch(() => alert('Falha ao alterar serviço do ensaio!'))
  }
  useEffect(() => {
    if (service) {
      if (service.id === session.service.id) {
        setError('O serviço selecionado é igual ao serviço atual')
      } else {
        session.products.forEach(product => {
          if (product.deliverables.length) {
            setError('O ensaio possui itens salvos. Por favor, remova antes de alterar o serviço.')
          }
        })
      }
    } else {
      setError('')
    }
  }, [service, session])
  return (
    <Modal size="lg">
      <ModalHeader title="Alterar serviço do ensaio" />
      <ModalBody className={classes.body}>
        <div>
          <FormGroup label="Serviço atual">
            <Input readOnly value={session.service.name} />
          </FormGroup>
          <h6 className={classes.title}>Entregáveis:</h6>
          {session.service.products.map(
            (product, i) =>
              product.item && (
                <div key={i} className={classes.product}>
                  <img
                    className={classes.icon}
                    src={`https://s3.amazonaws.com/assets.birdie.com.br/${product.item.icon}`}
                    alt={product.item.title}
                  />
                  <p className={classes.item}>{product.item.title}</p>
                </div>
              )
          )}
        </div>
        <Icon.ArrowRight className={classes.arrow} size="2x" />
        <div>
          <FormGroup label="Novo serviço">
            <ServiceDataSource clientId={session.client.id}>
              <InputAutocomplete onChange={setServiceId} />
            </ServiceDataSource>
          </FormGroup>
          <h6 className={classes.title}>Entregáveis:</h6>
          {service?.products.map((product, i) => (
            <div key={i} className={classes.product}>
              <img className={classes.icon} src={product.item?.icon} alt={product.item?.title} />
              <p className={classes.item}>{product.item?.title}</p>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        {error && <p className={classes.error}>{error}</p>}
        <Button disabled={error !== '' || service === undefined} onClick={handleSave} size="sm">
          CONFIRMAR
        </Button>
      </ModalFooter>
    </Modal>
  )
}
