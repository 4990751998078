import React, { useEffect, useCallback, useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Input } from 'shared/form/Input'
import { InputFloatLabel } from 'shared/form/InputFloatLabel'
import { tap, toFormData } from 'shared/util'

import { useProviderContext } from '../ProviderContext'

import classes from './ProviderCalendar.module.scss'

export const ProviderCalendar: React.FC = () => {
  const [map, setMap] = useState<google.maps.Map>()
  const [provider, setProvider] = useProviderContext()
  const [value, setValue] = useState({
    params: {
      google_calendar: {
        id: provider.params.google_calendar.id || ''
      }
    }
  })
  useEffect(() => {
    if (map) {
      const bounds = new google.maps.LatLngBounds()
      const polygons: [string, google.maps.Polygon][] = provider.regions.map(({ name, region }) => {
        const paths = region.map(([x, y]) => {
          return tap({lat: x, lng: y}, point => bounds.extend(point))
        })
        const polygon = new google.maps.Polygon({
          fillOpacity: 0.4,
          paths: paths,
          strokeOpacity: 0.8,
          strokeWeight: 2
        })
        polygon.setMap(map)
        return [name, polygon]
      })
      map.fitBounds(bounds)
      return () => polygons.forEach(([, polygon]) => polygon.setMap(null))
    }
  }, [map, provider])
  const handleMapLoad = useCallback((element: HTMLDivElement | null) => {
    if (element) {
      setMap(new google.maps.Map(element))
    } else {
      setMap(undefined)
    }
  }, [])
  const handleSave: React.MouseEventHandler = event => {
    const button = event.target as HTMLButtonElement
    button.setAttribute('disabled', 'disabled')
    const body = toFormData(value)
    fetch(`/api/providers/${provider.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      .then(({ data }) => setProvider(data))
      .catch(() => alert('Falha ao atualizar fornecedor!'))
      .then(() => button.removeAttribute('disabled'))
  }
  return (
    <div>
      <InputFloatLabel label="ID do Google Calendar">
        <Input
          onChange={id => setValue({ params: { google_calendar: { id } } })}
          initial={value.params.google_calendar.id}
        />
      </InputFloatLabel>
      <div className={classes.map} ref={handleMapLoad}>

      </div>
      <Button className={classes.save} type="button" onClick={handleSave}>
        SALVAR
      </Button>
    </div>
  )
}
