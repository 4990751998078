import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './assets/styles/index.scss'

import 'shared/ui/styles.scss'

import classes from './index.module.scss'

import { AuthContextProvider } from './components/auth/AuthContext'
import { App } from './App'

const Root: React.FC = props => (
  <BrowserRouter>
    <div className={classes.root}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </div>
  </BrowserRouter>
)

ReactDOM.render(<Root />, document.getElementById('root'))
