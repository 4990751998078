import React, { createElement } from 'react'
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { useProvider } from 'hooks/data/providers'

import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Card, CardBody } from 'shared/bootstrap/card'

import { ProviderInfo } from './tabs/ProviderInfo'
import { ProviderPayments } from './tabs/ProviderPayments'
import { ProviderServices as ProviderServicesOld } from './tabs/ProviderServices'
import { ProviderSessions } from './tabs/ProviderSessions'

import { ProviderContextProvider } from './components/ProviderContext'
import { ProviderViewInfo } from './components/ProviderViewInfo'
import { ProviderAdditional } from './components/additional/ProviderAdditional'
import { ProviderCalendar } from './components/calendar/ProviderCalendar'
import { ProviderDocuments } from './components/documents/ProviderDocuments'
// import { ProviderServices } from './components/services/ProviderServices'

import classes from './ProviderView.module.scss'

export const ProviderView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [provider, setProvider] = useProvider(match.params)
  const state = [provider!, setProvider] as App.ReactState<any>
  const section = (component: React.FC<{ state: App.ReactState<any> }>) => ({
    render: () => createElement(component, { state })
  })
  return (
    <ProviderContextProvider id={match.params.id}>
      <Breadcrumb>
        <BreadcrumbItem to="/providers">Fornecedores</BreadcrumbItem>
        <BreadcrumbItem>Visualizar fornecedor</BreadcrumbItem>
      </Breadcrumb>
      {provider && (
        <div className={classes.container}>
          <ProviderViewInfo />
          <div className={classes.column}>
            <Card className={classes.sections} shadow>
              <CardBody>
                <nav className={classes.nav}>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/sessions`}>
                    Ensaios
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/info`}>
                    Dados básicos
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/services`}>
                    Serviços
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/calendar`}>
                    Agenda
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/documents`}>
                    Documentos
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/additional`}>
                    Dados adicionais
                  </NavLink>
                  {/* <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/payments`}>
                    Pagamentos
                  </NavLink> */}
                  {/* <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/calendar`}>
                    Agenda
                  </NavLink> */}
                </nav>
                <Switch>
                  <Route path={`${match.path}/sessions`} {...section(ProviderSessions)} />
                  <Route path={`${match.path}/info`} {...section(ProviderInfo)} />
                  <Route path={`${match.path}/payments`} {...section(ProviderPayments)} />
                  <Route path={`${match.path}/additional`} component={ProviderAdditional} />
                  <Route path={`${match.path}/calendar`} component={ProviderCalendar} />
                  <Route path={`${match.path}/documents`} component={ProviderDocuments} />
                  <Route path={`${match.path}/services`} {...section(ProviderServicesOld)} />
                  {/* component={ProviderServices} */}
                  <Redirect to={`${match.url}/sessions`} />
                </Switch>
              </CardBody>
            </Card>
            {/* <Ratings provider={provider} /> */}
          </div>
        </div>
      )}
    </ProviderContextProvider>
  )
}
