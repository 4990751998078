import React, { useCallback, useEffect, useState } from 'react'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { Button } from 'shared/ui/button/Button'
import { toFormData } from 'shared/util/form-data'

import { ContractServiceItem } from 'pages/clients/client-add/pages/components/ContractServiceItem'

import classes from './SessionServiceEditModal.module.scss'

type ServiceType = Pick<Model.Service, 'name' | 'host_prize' | 'price' | 'products'>

interface Props {
  session: Resource.SessionResource
}

export const SessionServiceEditModal: React.FC<Props> = ({ session }) => {
  const [dirty, setDirty] = useState(false)
  const [state, setState] = useState<ServiceType>({
    name: session.service.name || '',
    host_prize: session.service.host_prize || 6,
    price: session.service.price || 0,
    products: session.service.products || []
  })
  const handleChange = useCallback((service: ServiceType) => {
    setState(service)
    setDirty(true)
  }, [])
  const [error, setError] = useState('')
  const handleSave = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ service: state })
    if (session.service.session_id) {
      body.set('service[id]', session.service.session_id.toString())
    }
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw Error()
        }
      })
      .catch(() => alert('Falha ao alterar serviço do ensaio!'))
  }
  useEffect(() => {
    setError('')
    session.service.products.forEach(product => {
      if (!state.products.find(({ item_id }) => item_id === product.item_id)) {
        const serviceProductSession = session.products.find(({ item }) => item.item?.id === product.item_id)
        if (serviceProductSession?.deliverables.length) {
          setError('Atenção: ao alterar entregáveis com conteúdo, informações nos painéis podem ser perdidas')
        }
      }
    })
  }, [session, state])
  return (
    <Modal size="lg">
      <ModalHeader title="Alterar serviço somente para o ensaio atual" />
      <ModalBody className={classes.body}>
        {session.service.session_id === null && (
          <p>
            Atenção: ao confirmar a alteração do serviço, quaisquer novas alterações impactarão somente esse ensaio.
          </p>
        )}
        <ContractServiceItem className={classes.service} onChange={handleChange} service={state} />
      </ModalBody>
      <ModalFooter>
        {error && <p className={classes.warning}>{error}</p>}
        <Button disabled={dirty === false} onClick={handleSave} size="sm">
          CONFIRMAR
        </Button>
      </ModalFooter>
    </Modal>
  )
}
