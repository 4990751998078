import React, { Fragment } from 'react'

import { Card, CardBody } from 'shared/bootstrap/card'
import { useModal } from 'shared/bootstrap/modal'
import { RoundButton } from 'shared/ui/button/RoundButton'
import { Icon } from 'shared/ui/icon'

import { SessionProviderModal } from './SessionProviderModal'
import { SessionServiceChangeModal } from './SessionServiceChangeModal'
import { SessionServiceEditModal } from './SessionServiceEditModal'

import classes from './SessionViewService.module.scss'

interface Props {
  className?: string
  session: Resource.SessionResource
}

export const SessionViewService: React.FC<Props> = ({ className, session }) => {
  const providerModal = useModal(SessionProviderModal, { session })
  const serviceChangeModal = useModal(SessionServiceChangeModal, { session })
  const serviceEditModal = useModal(SessionServiceEditModal, { session })
  const handleProvider = () => providerModal.current?.open()
  const handleServiceChange = () => serviceChangeModal.current?.open()
  const handleServiceEdit = () => serviceEditModal.current?.open()
  return (
    <Card className={classes.card} shadow>
      <CardBody className={classes.providerService}>
        <RoundButton className={classes.edit} type="button" onClick={handleServiceEdit}>
          <Icon.Edit />
        </RoundButton>
        <RoundButton className={classes.change} type="button" onClick={handleServiceChange}>
          <Icon.ExchangeAlt />
        </RoundButton>
        <h6>Serviço do Cliente</h6>
        <p className={classes.serviceName}>{session.service.name}</p>
        <hr />
        <h6>Fornecedores</h6>
        <div className={classes.providers}>
          {session.products.map(
            product =>
              product.item.work_type !== 4 && (
                <Fragment key={product.id}>
                  <span className={classes.label}>{product.item.label}</span>
                  <span>{product.provider.name || '-'}</span>
                  <span className={classes.cost}>R$ {product.cost || '-'}</span>
                </Fragment>
              )
          )}
        </div>
        <RoundButton className={classes.provider} type="button" onClick={handleProvider}>
          <Icon.Edit />
        </RoundButton>
        <hr />
        <p>{session.provider?.name || '-'}</p>
      </CardBody>
    </Card>
  )
}
