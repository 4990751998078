import React, { /* useEffect, */ useRef, useState } from 'react'

import { useDebouncedCallback } from '../util'

import { BaseDataSource, DataSourceItem } from './BaseDataSource'

interface Props {
  associableType: 'admin' | 'client' | 'provider'
  profile?: number
}

export const UserDataSource: React.FC<Props> = ({ associableType, profile, ...props }) => {
  const abortController = useRef<AbortController>()
  const [items, setItems] = useState<DataSourceItem[]>()
  const handleChange = useDebouncedCallback(
    (value: string) => {
      abortController.current?.abort()
      abortController.current = new AbortController()
      const signal = abortController.current?.signal
      const search = new URLSearchParams()
      search.set('length', '20')
      search.set('v', '2')
      search.set('value', value)
      search.set('associable_type', associableType)
      if (profile) {
        search.set('profile', profile.toString())
      }
      fetch(`/api/users?${search.toString()}`, { signal })
        .then(response => response.json())
        .then(response => response.data as Model.User[])
        .then(items => items.map(({ id, name }) => [id, name] as DataSourceItem))
        .then(items => setItems(items))
        .catch(error => {
          if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
            return null
          }
          throw new Error(error)
        })
    },
    [],
    300
  )
  return <BaseDataSource items={items} onChange={handleChange} {...props} />
}
