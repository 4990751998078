import React from 'react'

import { DropdownItem } from 'shared/bootstrap/dropdown/DropdownItem'

import { useInputDropdownContext } from './InputDropdownContext'

interface Props {
  className?: string
  children: string
  value: number | string
}

export const InputDropdownItem: React.FC<Props> = ({ children, value, ...props }) => {
  const context = useInputDropdownContext()
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    context.onClick([value, children])
  }
  return (
    <DropdownItem onClick={handleClick} {...props}>
      {children}
    </DropdownItem>
  )
}

/***
 * import React, { useEffect } from 'react'

import { DropdownItem } from 'shared/bootstrap/dropdown/DropdownItem'

import { useInputSelectContext } from './InputSelect'

interface Props {
  children: string
  className?: string
  value: number | string
}

export const InputSelectItem: React.FC<Props> = ({ children, value, ...props }) => {
  const inputSelectContext = useInputSelectContext()
  const handleClick = () => inputSelectContext.onClick([value, children])
  useEffect(() => {
    inputSelectContext.items.set(value, children)
    // return () => {
    //   inputSelectContext.items.delete(value)
    // }
  }, [children, inputSelectContext, value])
  return <DropdownItem children={children} onClick={handleClick} type="button" {...props} />
}

 */
