type PrimitiveValue = boolean | null | number | string | undefined | PrimitiveValue[]
type Data<T> = {
  [key: number | string]: PrimitiveValue | Data<T> | T
}
type Ref<T> = { current: T }

export function dataGet<U, T extends Data<U>>(target: T, key: string, defaultValue = null): U | null {
  const path = key.split('.').filter(key => key !== '')
  const ref = { current: target }
  while (path.length) {
    const [key] = path.splice(0, 1)
    if (key in target) {
      Object.assign(ref, { current: target[key] })
      if (path.length === 0) {
        return ref.current as U
      }
    } else {
      return defaultValue
    }
  }
  return defaultValue
}

export function dataSet<T extends Data, U>(target: T, key: string, value: U) {
  const obj = { ...target }
  const path = key.split('.')
  const ref = { current: target }
  while (path.length) {
    const [key] = path.splice(0, 1)
    if (key in ref.current) {
      
    }
    if (key in target) {
      Object.assign(ref, { current: target[key] })
    }
  }
  path.reduce((current, k, i) => {
    if (i === (path.length - 1)) {
      // Last key
    } else {
      return obj[k] = (Object.assign(obj, { [k]: obj[k] || { } }))
    }
  }, obj)
}

/**
 *
 * @param number The number to cast
 * @param locale The locale (default: pt-BR)
 */
 export function formatDecimal(value: string | number | null | undefined, digits = 2) {
  const number = Number(value)
  if (!isNaN(number)) {
    return number.toLocaleString('pt-BR', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    })
  }
}

export function parseNumber(input: string) {
  try {
    if (input.lastIndexOf(',') || input.lastIndexOf('.')) {
      const isComma = input.lastIndexOf(',') > input.lastIndexOf('.')
      if (isComma) {
        return Number(input.replace('.', '').replace(',', '.'))
      } else {
        return Number(input.replace(',', ''))
      }
    } else {
      return Number(input)
    }
  } catch {
    return null
  }
}

export function tap<T>(value: T, callback?: (_: T) => void) {
  callback?.(value)
  return value
}
