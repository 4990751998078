import React, { useCallback, useState } from 'react'

import { Item } from 'shared/enum/item'
import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { useServiceProductSession } from 'shared/hooks/data/service-product-session'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { Icon } from 'shared/ui/icon/Icon'
import { Table, TableBody, TableColumn, TableHeader, TablePaginator, TableRow } from 'shared/ui/table'
import { formatISO } from 'shared/util/date'
import { clipboardWrite } from 'shared/util/functions'
import { parseAddress } from 'shared/util/google-maps'

import classes from './DeliverableListTable.module.scss'

interface DeliverableListTableProps {
  className?: string
  filter?: { [key: string]: any }
}

interface DeliverableListTableItemProps {
  item: Model.Item
  products: any[]
}

const DeliverableListTableItem: React.FC<DeliverableListTableItemProps> = ({ item, products }) => {
  switch (item.id) {
    case Item.CAPTURE_TOUR:
    case Item.VIDEOS:
      return (
        <div>
          {products.map(product => (
            <div key={product.id} className={classes.product}>
              <Icon.Link className={classes.icon} />
              <a className={classes.file} href={product.url} rel="noopener noreferrer" target="_blank">
                {product.url}
              </a>
              <button className={classes.copy} onClick={clipboardWrite(product.url)}>
                <Icon.Clipboard />
              </button>
              <button className={classes.remove} onClick={clipboardWrite(product.id)}>
                <Icon.Times />
              </button>
            </div>
          ))}
        </div>
      )
    case Item.EDIT_PHOTOS:
    case Item.EDIT_TOUR:
      return (
        <div className={classes.photos}>
          {products.map(product => (
            <div key={product.id} className={classes.photo}>
              <a href={product.url} target="_blank" rel="noopener noreferrer">
                <img src={product.url} alt={'tag'} />
              </a>
            </div>
          ))}
        </div>
      )
    default:
      return null
  }
}

export const DeliverableListTable: React.FC<DeliverableListTableProps> = ({ filter, ...props }) => {
  const [meta, setMeta] = useState<Http.ResponseMeta>()
  const [paging, setPaging] = useState<Http.Pagination>({ page: 1, length: 50 })
  const handleMeta = useCallback(meta => setMeta(meta), [])
  const deliverables = useServiceProductSession(filter, paging, handleMeta)
  return (
    <div>
      <p className={classes.count}>{meta?.total || '-'} resultados encontrados</p>
      <Table hover strip {...props}>
        <TableHeader>
          <TableColumn name="id" align="center">
            Ensaio
          </TableColumn>
          <TableColumn name="deliverable" size={6}>
            Entregáveis
          </TableColumn>
        </TableHeader>
        <TableBody>
          {deliverables?.map(deliverable => {
            const address = parseAddress(deliverable.session.address?.address_components)
            return (
              <TableRow key={deliverable.id}>
                <TableColumn verticalAlign="flex-start">
                  <div className={classes.session}>
                    <div className={classes.header}>
                      <Button
                        className={classes.id}
                        to={`/sessions/${deliverable.session.id}`}
                        size="sm"
                        target="blank">
                        <span>{deliverable.session.id}</span>
                        <Icon.ExternalLinkSquareAlt />
                      </Button>
                      <Badge className={classes.badge} color="darkgrey">
                        {deliverable.item.item?.title}
                      </Badge>
                    </div>
                    <div>
                      <Badge color={getSessionStatusColor(deliverable.session.status)} size="sm">
                        {getSessionStatusDescription(deliverable.session.status)}
                      </Badge>
                    </div>
                    <div className={classes.address}>
                      {address.get('street')}
                      {address.has('number') && `, ${address.get('number')}`}
                    </div>
                    <div>
                      <p className={classes.label}>Agendamento</p>
                      <p className={classes.text}>
                        {deliverable.session.scheduled_for
                          ? formatISO(deliverable.session.scheduled_for, 'dd/MM/yyyy HH:mm')
                          : '-'}
                      </p>
                    </div>
                    <div>
                      <p className={classes.label}>Cliente</p>
                      <p className={classes.text}>{deliverable.session.client.name}</p>
                    </div>
                    <div>
                      <p className={classes.label}>Fornecedor</p>
                      <p className={classes.text}>{deliverable.session.provider?.name || '-'}</p>
                    </div>
                    {deliverable.delivered_at && (
                      <div className={classes.deliveredBy}>
                        Entregue por {deliverable.delivered_by.name} às{' '}
                        {formatISO(deliverable.delivered_at, 'dd/MM/yyyy HH:mm')}
                      </div>
                    )}
                  </div>
                </TableColumn>
                <TableColumn align="center" size={6}>
                  {deliverable.deliverables.length > 0 ? (
                    <DeliverableListTableItem item={deliverable.item} products={deliverable.deliverables} />
                  ) : (
                    <div className={classes.pending}>
                      <Icon.Hourglass className={classes.icon} />
                      <p className={classes.text}>Aguardando material</p>
                    </div>
                  )}
                  {deliverable.session.report?.notes && (
                    <p>Observações do fotógrafo: {deliverable.session.report.notes}</p>
                  )}
                  {deliverable.session.report?.additional_info?.lacking_areas && (
                    <p>Ambientes que faltaram: {deliverable.session.report.additional_info.lacking_areas}</p>
                  )}
                </TableColumn>
              </TableRow>
            )
          })}
        </TableBody>
        <TablePaginator meta={meta} onChange={(page, length) => setPaging({ page, length })} />
      </Table>
    </div>
  )
}
