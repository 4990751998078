import React, { useState } from 'react'

import { InputDecimal } from 'shared/form/InputDecimal'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { RoundButton } from 'shared/ui/button'
import { Icon } from 'shared/ui/icon'
import { classNames } from 'shared/util/class-names'
import { toFormData } from 'shared/util/form-data'

import { useSessionViewContext } from './SessionViewContext'

import classes from './SessionDetailPrice.module.scss'

interface Props {
  price: number | null
  sessionId: number
}

export const SessionDetailPrice: React.FC<Props> = ({ price, sessionId }) => {
  const context = useSessionViewContext()
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(price)
  const handleEdit = (editing: boolean) => (event: React.MouseEvent) => {
    event.preventDefault()
    setEditing(editing)
  }
  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ price: value })
    fetch(`/api/sessions/${sessionId}`, { body, method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error()
        } else {
          context.onChange()
          setEditing(false)
        }
      })
      .catch(() => alert('Falha ao atualizar preço do ensaio'))
  }
  return (
    <div>
      <p className={classes.title}>Preço do Ensaio:</p>
      <div className={classNames(classes.container, editing && classes.editing)}>
        <div className={classes.show}>
          <p className={classes.price}>
            R$ {value?.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || '-'}
          </p>
          <RoundButton className={classes.button} onClick={handleEdit(true)}>
            <Icon.Pen />
          </RoundButton>
        </div>
        <div className={classes.edit}>
          <RoundButton className={classes.cancel} onClick={handleEdit(false)} variant="text">
            <Icon.Times />
          </RoundButton>
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal initial={price || ''} onChange={setValue} scale={2} />
          </InputGroup>
          <RoundButton className={classes.button} onClick={handleSubmit}>
            <Icon.Check />
          </RoundButton>
        </div>
      </div>
    </div>
  )
}
