import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { SessionDeliverables } from 'shared/components/deliverables/SessionDeliverables'
import { useSession } from 'shared/hooks/data/session'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { Container } from 'shared/ui/container/Container'
import { classNames } from 'shared/util/class-names'

import { SessionDetail } from './components/SessionDetail'
import { SessionInfo } from './components/SessionInfo'
import { SessionViewContextProvider } from './components/SessionViewContext'
import { SessionViewService } from './components/SessionViewService'
import { VerticalData } from './components/VerticalData'

import classes from './SessionView.module.scss'

export const SessionView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [param, setParam] = useState(match.params)
  const session = useSession(param)
  const handleChange = useCallback(() => setParam(({ id }) => ({ id })), [])

  if (!session) {
    return null
  }

  return (
    <SessionViewContextProvider onChange={handleChange}>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem to="/sessions">Ensaios</BreadcrumbItem>
          <BreadcrumbItem>Ensaio {session.id}</BreadcrumbItem>
        </Breadcrumb>
        <main className={classNames(classes.wrapper, classes.grid)}>
          <div className={classes.sideline}>
            <SessionDetail session={session} onChange={handleChange} />
            <SessionViewService session={session} />
            <VerticalData session={session} />
          </div>
          <div className={classes.sidecolumn}>
            <SessionInfo session={session} />
            <SessionDeliverables className={classes.wrapper} session={session} category="admin" />
          </div>
        </main>
      </Container>
    </SessionViewContextProvider>
  )
}
