import React, { Fragment, useEffect, useState } from 'react'

import classes from './Services.module.scss'

import { Icon } from 'shared/ui/icon'
import { FormCheck } from 'shared/form/FormCheck'
import { FormGroup } from 'shared/form/FormGroupNew'
import { FormMasked } from 'shared/form/FormMasked'
import { InputDecimal } from 'shared/form/InputDecimal'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'

import { useItemsState } from 'hooks/data/item'
import { Button } from 'components/Button'
import { FormText } from 'shared/form'
import { ItemWorkType } from 'shared/enum/item'

interface FormPivotProps {
  initState?: any
  dispatch?: (partial: any) => void
}

const FormPivot: React.FC<FormPivotProps> = ({ dispatch, initState }: any) => {
  const [pivot, setPivot] = useState<any>(initState)

  useEffect(() => {
    dispatch?.(pivot)
    // TODO: add dispatch on dep array
    // eslint-disable-next-line
  }, [pivot])

  return (
    <>
      <FormCheck
        onChange={exists => setPivot(exists ? { deliverable: false } : undefined)}
        checked={pivot !== undefined}
      />
      <FormCheck
        onChange={deliverable => setPivot(deliverable ? { deliverable } : { deliverable: false })}
        checked={pivot !== undefined && Boolean(pivot.deliverable)}
        readOnly={pivot === undefined}
      />
      <FormMasked
        mask="currency"
        readonly={!Boolean(pivot?.deliverable)}
        onChange={cost => setPivot({ ...pivot, cost })}
        value={`${pivot?.cost}`}
      />
    </>
  )
}
interface ListItemProps {
  items?: any[]
  onChange?: (id: number, partial?: Partial<Model.Item>) => void
}

const ListItem: React.FC<ListItemProps> = ({ items = [], onChange }) => {
  return (
    <>
      <div className={classes.row}>
        <p>RELAÇÃO</p>
        <p>SERVIÇO</p>
        <p className={classes.center}>VISIBILIDADE</p>
        <p className={classes.center}>ENTREGA</p>
        <p>VALOR</p>
      </div>
      <FormGroup>
        {items?.map((item: any, index: number) => {
          const dispatch = (partial: any) => {
            onChange?.(item.id, partial)
          }

          return (
            <Fragment key={index}>
              <FormText readOnly value={item?.item?.title} />
              <FormText readOnly value={item?.title} />
              <FormPivot initState={item?.pivot} dispatch={(pivot: any) => dispatch({ pivot })} />
            </Fragment>
          )
        })}
      </FormGroup>
    </>
  )
}

export const ProviderServices: React.FC = ({ state }: any) => {
  const [provider] = state
  const [items] = useItemsState()
  const [providerItems, setProviderItems] = useState<Model.Item[]>([])
  const [saved, setSaved] = useState(true)
  const [additionalArea, setAdditionalArea] = useState(provider?.costs?.additional_area || '')
  const [displacement, setDisplacement] = useState(provider?.costs?.displacement || '')

  const onItemChange = (id: number, partial?: Partial<Model.Item>) => {
    let updatedProviderItems = providerItems
    let index = providerItems.findIndex(item => item.id === id)

    if (partial !== undefined) {
      updatedProviderItems[index] = { ...items[index], ...updatedProviderItems[index], ...partial }
    } else {
      updatedProviderItems[index] = { ...updatedProviderItems[index], pivot: undefined }
    }

    setProviderItems(updatedProviderItems)
  }

  // this useEffect merge the two arrays
  useEffect(() => {
    if (provider.items) {
      let newProviderItems = items

      if (newProviderItems) {
        for (let providerItem of provider.items) {
          let index = items.findIndex(item => item.id === providerItem.id)

          if (index !== -1) {
            newProviderItems[index] = { ...newProviderItems[index], ...providerItem }
          }
        }

        setProviderItems(newProviderItems)
      }
    }
  }, [items, provider])

  const onClick = () => {
    setSaved(false)
    const body = {
      costs: {
        additional_area: additionalArea,
        displacement: displacement
      },
      products: providerItems
        .filter(item => item.pivot !== undefined)
        .map(item => ({ id: item.id, cost: item.pivot?.cost, deliverable: item.pivot?.deliverable }))
    }

    fetch(`/api/providers/${provider.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...body })
    }).then(() => setTimeout(() => setSaved(true), 1000))
  }

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <FormGroup label="Custo Deslocamento">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal initial={displacement} onChange={setDisplacement} scale={2} />
          </InputGroup>
        </FormGroup>
        <FormGroup label="Custo Área Adicional">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal initial={additionalArea} onChange={setAdditionalArea} scale={2} />
          </InputGroup>
        </FormGroup>
      </div>
      <hr />
      <div className={classes.form}>
        <h3>Produtos locais (precisa ir até a sessão)</h3>
        <ListItem
          onChange={onItemChange}
          items={providerItems?.filter(item => item.work_type === ItemWorkType.LOCAL)}
        />
        <h3 style={{ marginTop: '30px' }}>Produtos remotos (Edição de vídeo/imagem, planta, etc)</h3>
        <ListItem
          onChange={onItemChange}
          items={providerItems?.filter(item => item.work_type === ItemWorkType.REMOTE)}
        />
      </div>
      <p style={{ padding: '15px 15px', backgroundColor: '#F6F6F6', lineHeight: '22px', fontSize: '13px' }}>
        <strong>Visibilidade: </strong> Os painéis são visíveis para o fornecedor, porém não são alteráveis.
        <br />
        <strong>Entrega: </strong> Os painéis são habilitados para o fornecedor enviar material.
      </p>
      <div className={classes.row}>
        <Button onClick={onClick}>Salvar</Button>
        <div className={classes.check}>{saved ? <Icon.CheckCircle /> : <Icon.CircleNotch spin />}</div>
      </div>
    </Fragment>
  )
}
