import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

export const Wrapper = styled.div`
  display: block;
  max-width: 92%;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 60px;
  transition: all 300ms ease-in-out;

  @media only screen and (min-width: 540px) {
  }

  @media only screen and (min-width: 720px) {
    width: 94%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 960px) {
    width: 98%;
    display: block;
  }
`

export const Main = styled.main`
  display: flex;
`

export const Line = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  &.between {
    justify-content: space-between;
  }

  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 10px 0;
    grid-gap: 15px;

    div,
    img,
    ul,
    li,
    p {
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  &.final {
    margin: 0 1%;
    justify-content: start;
    align-items: center;
  }
`

export const Inline = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;

  label {
    flex: 1;
  }
`

export const Left = styled.div`
  display: flex;
  align-items: center;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
`

export const Card = styled.div`
  display: flex;
  padding: 20px 25px;
  border-radius: 10px;
  background: #fff;
  margin: 0;
  box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;

  &.third {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  &.nopadding {
    max-width: 490px;
    padding: 0;
  }

  &.enterprise {
    max-width: 315px;
    height: fit-content;
  }

  &.black {
    border-radius: 10px;
    background: #000;
    box-shadow: 0px 0px 10px rgba(127, 127, 127, 0.3);
    color: white;

    p {
      color: white;
    }

    button {
      border-color: ite;
      color: white;

      &:hover {
        cursor: pointer;
        background: white;
        color: black;
        border-color: white;
      }
    }
  }

  &.bigWidth {
    max-width: 100%;
    flex-direction: column;
  }

  &.center {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      text-align: center;
    }
  }
`

export const Call = styled.h2`
  line-height: 20px;
  padding: 0;
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #000;

  &.final {
    color: #00b200;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const Breadcump = styled.div`
  display: flex;
  flex-direction: row;
`

export const Item = styled.p`
  font-size: 14px;
  line-height: 17px;

  &:before {
    content: '>';
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
`

export const CardHeader = styled.div`
  min-width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;

  &.borderBottom {
    border-bottom: 1px solid #999999;
  }
`

export const MyMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  width: 100%;
  box-sizing: border-box;
`

export const Image = styled.div`
  width: 120px;
  height: 60px;
  background: #e9e9e9;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  padding: 10px;
  margin: 10px auto;
`

export const Img = styled.img`
  width: 100%;
  height: auto;
  margin: 0 auto;
`

export const ListIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`

export const ItemIcon = styled.img`
  max-width: 30px;
  width: 100%;
  height: auto;
  margin: 0 15px;

  &.noMargin {
    margin: 0 10px 0 0;
  }

  &.marginzero {
    margin: 0;
  }

  &.tiny {
    margin: 0 5px;
    max-width: 12px;
  }
`

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 5px 0;
  color: #000;

  &.left {
    text-align: left;
  }

  &.bottom {
    margin-bottom: 30px;
  }
`

export const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  align-items: center;
  display: flex;
  margin: 0;
  color: #4c4c4c;

  &.big {
    font-size: 26px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`

export const ItemID = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 5px 0;
  color: #000;
`

export const CardMain = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 15px 0;
  box-sizing: border-box;
  border-top: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f;
`

export const CNPJ = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 0;
  text-align: left;
  color: #7f7f7f;
`

export const DateCreated = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 0;
  text-align: left;
  color: #7f7f7f;
`

export const Adress = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 0;
  text-align: left;
  color: #7f7f7f;
`

export const CardFooter = styled.div``

export const IconOk = styled(ReactSVG)`
  margin: 0 20px;
  display: inline-flex;

  svg {
    width: 19px;
    height: 18px;
  }
`

export const IconEdit = styled(ReactSVG)`
  margin: 0 10px;
  display: inline-flex;

  svg {
    width: 25px;
    height: 19px;
  }
`

export const IconTrash = styled(ReactSVG)`
  margin: 0 10px;
  display: inline-flex;

  svg {
    width: 16px;
    height: 19px;
  }
`

export const Send = styled(Link)`
  path {
    transition: all 300ms ease-in-out;
  }

  &:hover {
    path {
      fill: #000;
    }
  }
`

export const Danger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

export const Cancelar = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  color: #000;
`

export const Deletar = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  color: #000;
`

export const Form = styled.form``

export const DadosForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 720px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  box-sizing: border-box;

  &.duo {
    max-width: 195px;
  }

  &.middle {
    margin: 0 20px 10px;
  }
`

export const Input = styled.input`
  height: 38px;
  border-radius: 6px;
  border: 1px solid #7f7f7f;
  padding: 10px 15px;
  margin-top: 5px;
  box-sizing: border-box;
`

export const Select = styled.select`
  height: 38px;
  border-radius: 6px;
  border: 1px solid #7f7f7f;
  padding: 10px 15px;
  margin-top: 5px;
  box-sizing: border-box;
`

export const Option = styled.option``

export const Upload = styled.input`
  width: 100%;
  height: 94px;
  border-radius: 6px;
  background: #fff;
  border: 1px dashed #7f7f7f;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;

  &::-webkit-file-upload-button {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: transparent;
    border: none;
  }

  span {
    display: none;
  }
`

export const Duo = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 195px;
  box-sizing: border-box;

  input {
    max-width: 112px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
`

export const Box = styled.div`
  color: #7f7f7f;
  height: 38px;
  margin-top: 5px;
  padding: 8px 15px;
  background: #d9d9d9;
  border: 1px solid #7f7f7f;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
`

export const CardGray = styled.div`
  border-radius: 6px;
  background: #f2f2f2;
  width: 100%;
  padding: 25px;
  flex-direction: column;

  p {
    text-align: left;
  }
`

export const One = styled.div`
  margin: 20px 1%;
  flex: 1;
`

export const Two = styled.div`
  margin: 20px 1%;
  flex: 1;
`

export const BigLink = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #0aa0d2;
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

export const MyOverlay = styled.div`
  width: 60%;
  background: white;
  padding: 3rem;
  border-radius: 5px;
`

export const CardForm = styled.div`
  background: #f2f2f2;
  border-radius: 12px;
  margin-bottom: 30px;
  padding: 25px 30px;
`

export const TableCardForm = styled.form`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;
  margin-bottom: 30px;

  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr;

    label.area {
      grid-area: 3 / 1 / 3 / span 2;
    }
  }

  @media only screen and (min-width: 1124px) {
    grid-template-columns: 1fr 1fr 1fr;

    label.area {
      grid-area: 2 / 2 / 2 / span 2;
    }
  }

  &.two-columns {
    @media only screen {
      grid-template-columns: 1fr 1fr;

      label.area {
        grid-area: 3 / 1 / 3 / span 2;
      }
    }
  }
`

export const TableCenterForm = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1124px) {
    grid-template-columns: 1fr 2fr;
  }
`

export const Side = styled.div``

export const CardSideForm = styled.form`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 1124px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  width: 100%;

  &.sessions {
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr 1fr;
  }

  &.services {
    grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr;
  }

  &.transactions {
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  }

  &.vouchers {
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  }

  &.usuarios {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

export const ClientServiceItem = styled.span`
  padding: 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;

  &.first-line {
    font-weight: bold;
    border-bottom: 2px solid black;
  }

  &.text-left {
    text-align: left;
  }

  &.text-center {
    text-align: center;
  }

  &.light {
    background-color: #fff;
  }

  &.dark {
    background-color: #f1f1f1;
  }
`

export const ItemTable = styled.span`
  padding: 15px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;
  cursor: pointer;

  &.first-line {
    font-weight: bold;
    border-bottom: 2px solid black;
  }

  &.text-left {
    text-align: left;
  }

  &.text-center {
    text-align: center;
  }

  &.light {
    background-color: #fff;
  }

  &.dark {
    background-color: #f1f1f1;
  }
`

export const Textartea = styled.textarea`
  min-height: 120px;
  border-radius: 6px;
  border: 1px solid #7f7f7f;
  padding: 10px 15px;
  margin-top: 5px;
  box-sizing: border-box;
  resize: vertical;
`

export const TextLine = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 960px) {
    flex-direction: row;

    label {
      flex: 1;

      &:last-child {
        margin-left: 15px;
      }
    }
  }
`

export const Checkbox = styled.label`
  font-size: 16px;
  line-height: 20px;
  flex-direction: row;
  margin: 0 0 20px 0;

  input {
    margin: 0;
    height: 12px;
  }
`

export const CallAdd = styled(Link)`
  display: flex;
  flex-direction: row;
`

export const CallImg = styled.img`
  max-width: 35px;
  width: 100%;
  height: auto;
  margin-right: 5px;
`

export const CallText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #0aa0d2;
`

export const Add = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AddImg = styled.img`
  max-width: 35px;
  width: 100%;
  height: auto;
  margin-right: 5px;
`

export const AddText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #0aa0d2;
`
