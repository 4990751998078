import React, { Fragment } from 'react'
import { addSeconds } from 'date-fns'

import { CancelSessionModal } from 'shared/components/sessions/CancelSessionModal'
import { SessionStatus } from 'shared/enum/session/SessionStatus'

import { DeleteSessionModal } from 'components/sessions/DeleteSessionModal'
import { DeliverSessionModal } from 'components/sessions/DeliverSessionModal'

import { Card, CardBody } from 'shared/bootstrap/card'
import { useModal } from 'shared/bootstrap/modal'
import { getSessionStatusColor, getSessionStatusDescription } from 'shared/enum/session/SessionStatus'
import { Badge } from 'shared/ui/badge/Badge'
import { Button } from 'shared/ui/button/Button'
import { RoundButton } from 'shared/ui/button/RoundButton'
import { Icon } from 'shared/ui/icon'
import { useModal as useOldModal } from 'shared/modal'
import { formatDate, formatISO } from 'shared/util/date'
import { toFormData } from 'shared/util/form-data'
import { parseAddress } from 'shared/util/google-maps'
import { toAsset } from 'shared/util/url'

import { SessionDetailPrice } from './SessionDetailPrice'
import { SessionEdit } from './SessionEdit'

import classes from './SessionDetail.module.scss'

interface SessionDetailProps {
  onChange: () => void
  session: Resource.SessionResource
}

export const SessionDetail: React.FC<SessionDetailProps> = ({ onChange, session }) => {
  const address = parseAddress(session.address?.address_components)
  const cancelModal = useOldModal(CancelSessionModal)
  const deleteModal = useOldModal(DeleteSessionModal)
  const deliverModal = useOldModal(DeliverSessionModal)
  const editModal = useModal(SessionEdit, { session })

  const handleCancel = () => cancelModal.open({ category: 'admin', onChange, session })
  const handleClone = () => {
    const body = toFormData({
      address: session.address,
      client: { id: session.client.id },
      price: session.price,
      //notification: session.notification,
      responsible_information: session.responsible_information,
      service: { id: session.service.id },
      vertical_data: session.vertical_data
    })
    const page = window.open('about:blank', '_blank')
    fetch(`/api/sessions`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      .then(({ data }) => {
        if (page) {
          page.location.href = `${window.location.origin}/sessions/${data.id}`
        }
      })
      .catch(() => alert('Falha ao duplicar ensaio!'))
  }
  const handleDelete = () => deleteModal.open({ onChange, session })
  const handleDeliver = () => deliverModal.open({ onChange, session })
  const handleEdit = () => editModal.current?.open()

  return (
    <Card className={classes.detail} shadow>
      <CardBody>
        <div className={classes.header}>
          <RoundButton className={classes.delete} type="button" onClick={handleDelete}>
            <Icon.TrashAlt />
          </RoundButton>
          <RoundButton type="button" onClick={handleClone}>
            <Icon.Copy />
          </RoundButton>
          <RoundButton type="button" onClick={handleEdit}>
            <Icon.Edit />
          </RoundButton>
        </div>
        <div className={classes.session}>
          <div className={classes.image}>
            {session.client.logo_url ? <img src={toAsset(session.client.logo_url)} alt="Logo" /> : 'Sem logo'}
          </div>
          <span className={classes.client}>{session.client.name}</span>
          <p className={classes.id}>ID {session.id}</p>
          <Badge color={getSessionStatusColor(session.status)}>{getSessionStatusDescription(session.status)}</Badge>
          {/*session.report?.additional_info?.displacement === true && (
            <strong className={classes.createdAt}>DESLOCAMENTO</strong>
          )}
          {session.report?.additional_info?.displacement === false && (
            <strong className={classes.createdAt}>
              {isBefore(subHours(scheduledFor, 24), canceledAt) ? 'DENTRO DE 24H' : 'ANTERIOR A 24H'}
            </strong>
          )*/}
        </div>
        <p className={classes.createdAt}>
          Criado em {formatISO(session.created_at, 'dd/MM/yyyy')} por {session.user.name}
        </p>
        <SessionDetailPrice price={session.price} sessionId={session.id} />
        <hr />
        <div className={classes.scheduledFor}>
          {session.scheduled_for ? (
            <Fragment>
              <p className={classes.day}>{formatISO(session.scheduled_for!, 'EEEE, dd/MM/yy')}</p>
              <p className={classes.hour}>
                {formatISO(session.scheduled_for!, 'HH:mm')}
                {' - '}
                {formatDate(addSeconds(new Date(session.scheduled_for!), session.duration), 'HH:mm')}
              </p>
              {session.status === SessionStatus.SCHEDULED && (
                <div className={classes.actions}>
                  <Button href={`${window.location.origin.replace('admin', 'client')}/calendar/${session.token}`}>
                    REAGENDAR COMO CLIENTE <Icon.ExternalLinkSquareAlt />
                  </Button>
                </div>
              )}
            </Fragment>
          ) : (
            session.status === SessionStatus.NOT_SCHEDULED && (
              <div className={classes.actions}>
                <Button href={`${window.location.origin.replace('admin', 'client')}/calendar/${session.token}`}>
                  AGENDAR COMO CLIENTE <Icon.ExternalLinkSquareAlt />
                </Button>
              </div>
            )
          )}
        </div>
        <div className={classes.address}>
          <div>
            <div>
              {address.get('street')}
              {address.has('number') && `, ${address.get('number')}`}
              {session.vertical_data?.unit && `/${session.vertical_data?.unit}`}
            </div>
            {session.vertical_data?.complement && <div>{session.vertical_data?.complement}</div>}
            <div>
              {address.get('neighborhood')}, {address.get('city')}/{address.get('state')}
            </div>
          </div>
        </div>
        {session.status !== SessionStatus.CANCELED && session.status !== SessionStatus.DELIVERED && (
          <div className={classes.actions}>
            <Button className={classes.button} outline type="button" onClick={handleDeliver}>
              <Icon.Check className={classes.check} />
              <span>REALIZADO</span>
            </Button>
            {/*<Button className={classes.button} outline variant="warning" onClick={handleReportProblem}>
              <Icon.ExclamationCircle />
              <span>REPORTAR PROBLEMA</span>
            </Button>*/}
            <Button className={classes.button} outline size="sm" type="button" variant="danger" onClick={handleCancel}>
              <Icon.Times className={classes.cancel} />
              <span>CANCELAR</span>
            </Button>
          </div>
        )}
        {/*session.status === SessionStatus.DELIVERED && (
          <div className={classes.actions}>
            <Button className={classes.button} outline variant="warning"></Button>
          </div>
        )*/}
      </CardBody>
    </Card>
  )
}
