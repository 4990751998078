import React from 'react'

import { classNames } from 'shared/util/class-names'

interface Props {
  className?: string
  label: string
}

export const InputFloatLabel: React.FC<Props> = ({ children, className, label }) => {
  return (
    <div className={classNames('form-floating', className)}>
      {children}
      <label>{label}</label>
    </div>
  )
}
