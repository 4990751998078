import React, { useContext, useEffect } from 'react'
import { useFilterContext } from './filters'
import { useSessionContext } from './sessions'
import { usePaginationContext } from './pagination'
import { useLoadingContext } from './loading'
import { Sort, useSortContext } from './sort'

const getSessions = (callback: any, page = 1, filter = {}, sort: Optional<Sort>) => {
  fetch(`/api/sessions/search?v=2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ page, filter, ...sort })
  })
    .then(response => response.json())
    .then(callback)
}

function useMeta(): [any] {
  const [filter] = useFilterContext()
  const [, setSessions] = useSessionContext()
  const [{ page }, setPagination] = usePaginationContext()
  const [, setLoad] = useLoadingContext()
  const [sort] = useSortContext()

  const reducer = (action: string) => {
    switch (action) {
      case 'update':
        setLoad(true)

        getSessions(
          ({ data, last_page, total_sessions }: any) => {
            setSessions(data || [])
            setPagination('meta', {
              totalSessions: total_sessions,
              lastPage: last_page
            })

            setLoad(false)
          },
          page,
          filter,
          sort
        )
        break
    }
  }

  const update = () => reducer('update')

  // eslint-disable-next-line
  useEffect(update, [page, sort])

  return [reducer]
}

const MetaContext = React.createContext<[any] | undefined>(undefined)

export const useMetaContext = () => useContext(MetaContext)!

export const MetaProvider: React.FC = props => {
  const meta = useMeta()

  return <MetaContext.Provider value={meta} {...props} />
}
