import React, { Fragment } from 'react'

import { Navbar } from 'shared/components/navbar'

import { useAuthContext } from './components/auth/AuthContext'
import { NavbarLinks } from './components/NavbarLinks'
import { Routes } from 'Routes'

import classes from './App.module.scss'

export const App: React.FC = () => {
  const [auth] = useAuthContext()
  return (
    <Fragment>
      <Navbar className={classes.navbar} menuVisible={Boolean(auth)}>
        {auth && (
          <NavbarLinks auth={auth} />
        )}
      </Navbar>
      <div className={classes.wrapper}>
        <Routes />
        {/*<Footer />*/}
      </div>
    </Fragment>
  )
}
