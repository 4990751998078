import React, { createContext, useContext /*, useRef */ } from 'react'

interface Context {
  items: Map<number | string, string>
  onClick: (_: [number | string, string]) => void
}

interface Props {
  // onChange?: (_: [number | string, string]) => void
  value: Context
}

const InputDropdownContext = createContext<Context>(undefined!)

export function useInputDropdownContext() {
  return useContext(InputDropdownContext)
}

export const InputDropdownContextProvider: React.FC<Props> = props => {
  return <InputDropdownContext.Provider {...props} />
}
