import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Steps } from 'components/Steps'
import { Button } from 'components/Button'

import { FormGroup } from 'shared/form/FormGroup'
import { FormMasked } from 'shared/form/FormMasked'
import { FormText } from 'shared/form/FormText'
import { toFormData } from 'shared/util/form-data'

import { useClientAddContext } from '../ClientAddContext'

import classes from './AddUser.module.scss'

export const AddUser: React.FC<RouteComponentProps> = ({ history }) => {
  const [context, setContext] = useClientAddContext()
  const [, setUser] = useState({
    name: context.users?.[0].name || '',
    email: context.users?.[0].email || '',
    password: context.users?.[0].password || '',
    phone: context.users?.[0].phone || ''
  })
  const handleChange = (key: string) => (value: string) => {
    setUser(u => {
      const user = { ...u, [key]: value }
      setContext(context => ({ ...context, users: [u] }))
      return user
    })
  }
  const handleBack = () => history.goBack()
  const handleNext = (event: React.FormEvent) => {
    event.preventDefault()
    const data = toFormData(context)
    const request: RequestInit = {
      body: data,
      method: 'POST'
    }
    fetch(`/api/clients`, request)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to add client')
        }
      })
      .then(({ data }) => {
        setContext(context => ({ ...context, id: data.id }))
        history.push(`/clients/add/confirm`)
      })
  }
  return (
    <Fragment>
      <Steps className="third" />
      <h2 className={classes.title}>Adicionar Usuário</h2>
      <form onSubmit={handleNext}>
        <FormGroup label="Nome">
          <FormText onChange={handleChange('name')} required />
        </FormGroup>
        <FormGroup label="Senha">
          <FormText onChange={handleChange('password')} required />
        </FormGroup>
        <FormGroup label="E-mail">
          <FormText type="email" onChange={handleChange('email')} required />
        </FormGroup>
        <FormGroup label="Telefone (opcional)">
          <FormMasked mask="phone" onChange={handleChange('phone')} />
        </FormGroup>
        <div className={classes.actions}>
          <Button onClick={handleBack} outline type="button">
            VOLTAR
          </Button>
          <Button>AVANÇAR →</Button>
        </div>
      </form>
    </Fragment>
  )
}
