import React, { useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Input } from 'shared/form/Input'
import { InputFloatLabel } from 'shared/form/InputFloatLabel'
import { toFormData } from 'shared/util'

import { useProviderContext } from '../ProviderContext'

export const ProviderAdditional: React.FC = () => {
  const [provider, setProvider] = useProviderContext()
  const [value, setValue] = useState({
    params: {
      em_casa: {
        id: provider.params.em_casa?.id || ''
      }
    }
  })
  const handleSave: React.MouseEventHandler = event => {
    const button = event.target as HTMLButtonElement
    button.setAttribute('disabled', 'disabled')
    const body = toFormData(value)
    fetch(`/api/providers/${provider.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error()
        }
      })
      // .then(({ data }) => setProvider(data))
      .catch(() => alert('Falha ao atualizar fornecedor!'))
      .then(() => button.removeAttribute('disabled'))
  }
  return (
    <div>
      <InputFloatLabel label="ID do Fornecedor na EmCasa">
        <Input onChange={id => setValue({ params: { em_casa: { id } } })} initial={value.params.em_casa.id} />
      </InputFloatLabel>
      <Button type="button" onClick={handleSave}>
        SALVAR
      </Button>
    </div>
  )
}
