import React from 'react'

import { classNames } from 'shared/util/class-names'

import classes from './NavbarToggle.module.scss'

interface Props {
  onClick: () => void
  opened: boolean
}

export const NavbarToggle: React.FC<Props> = ({ onClick, opened }) => {
  const handleClick: React.MouseEventHandler = event => {
    event.preventDefault()
    event.stopPropagation()
    onClick()
  }
  return (
    <button className={classNames(classes.button, opened && classes.opened)} onClick={handleClick} />
  )
}