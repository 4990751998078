import React, { useState } from 'react'

import { Container } from 'shared/ui/container'
import { classNames } from 'shared/util/class-names'

import { Navbox } from './Navbox'
import { NavbarToggle } from './NavbarToggle'

import classes from './Navbar.module.scss'

interface Props {
  className?: string
  menuVisible?: boolean
}

export const Navbar: React.FC<Props> = ({ className, menuVisible, ...props }) => {
  const [opened, setOpened] = useState(false)
  return (
    <nav className={classNames(classes.nav, className)}>
      <Container>
        <div className={classes.content}>
          <img src="/img/p-piperz.svg" alt="Piperz" />
          {menuVisible && (
            <div className={classes.menu} onClick={() => setOpened(false)}>
              <NavbarToggle onClick={() => setOpened(opened => !opened)} opened={opened} />
              <Navbox opened={opened} {...props} />
            </div>
          )}
        </div>
      </Container>
    </nav>
  )
}