import React, { createContext, useContext } from 'react'

interface Context {
  onChange: () => void
}

const SessionViewContext = createContext<Context>(undefined!)

export function useSessionViewContext() {
  return useContext(SessionViewContext)
}

export const SessionViewContextProvider: React.FC<Context> = ({ children, ...context }) => (
  <SessionViewContext.Provider value={context} children={children} />
)
