import React, { useEffect, useRef, useState } from 'react'

import { useDebouncedCallback } from '../util'

import { BaseDataSource, DataSourceItem } from './BaseDataSource'

interface AddressDataSourceProps {
  type: 'address' | 'city'
}

const autoCompleteService = new google.maps.places.AutocompleteService()
const geocoder = new google.maps.Geocoder()

export const AddressDataSource: React.FC<AddressDataSourceProps> = ({ children, type }) => {
  const [items, setItems] = useState<DataSourceItem<google.maps.GeocoderResult>[]>()
  const handleChange = useDebouncedCallback(
    (value: string) => {
      const placePredictionsRequest: google.maps.places.AutocompletionRequest = {
        input: value,
        types: [type === 'city' ? '(cities)' : type],
        componentRestrictions: {
          country: ['BR']
        }
      }
      autoCompleteService.getPlacePredictions(placePredictionsRequest, ([result]) => {
        if (result) {
          // geocoder.current.geocode({placeId: result.place_id}, ([place]) => {
          // })
        } else {
          setItems([])
        }
      })
    },
    [],
    300
  )
  useEffect(() => {
    handleChange('')
  }, [])
  return (
    <BaseDataSource items={items} onChange={handleChange}>
      {children}
    </BaseDataSource>
  )
}
