import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { ProviderDetail, useProvider } from 'shared/hooks/data/provider'

interface Props {
  id: string
}

type ContextType = [ProviderDetail, React.Dispatch<ProviderDetail>]

const Context = createContext<ContextType>(undefined!)

export function useProviderContext() {
  return useContext(Context)!
}

export const ProviderContextProvider: React.FC<Props> = ({ id, ...props }) => {
  const key = useMemo(() => ({ id }), [id])
  const data = useProvider(key)
  const [provider, setProvider] = useState(data)
  useEffect(() => setProvider(data), [data])
  if (provider) {
    return <Context.Provider value={[provider, setProvider]} {...props} />
  } else {
    return null
  }
}
