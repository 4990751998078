import React, { useCallback, useState } from 'react'

import { Button } from 'components/Button'
import { ContractServiceItem } from 'pages/clients/client-add/pages/components/ContractServiceItem'
import { Modal, ModalBody, ModalFooter, ModalHeader, useModalContainerContext } from 'shared/bootstrap/modal'
import { toFormData } from 'shared/util/form-data'

import classes from './ServiceFormModal.module.scss'

type ServiceType = Pick<Model.Service, 'name' | 'host_prize' | 'price' | 'prices' | 'products' | 'sla'>

interface Props {
  client?: Model.Client
  service?: Model.Service | Partial<Model.Service>
  onSubmit?: (_: Partial<Model.Service>) => void
}

export const ServiceFormModal: React.FC<Props> = ({ client, service, onSubmit }) => {
  const context = useModalContainerContext()
  const [state, setState] = useState<ServiceType>({
    name: service?.name || '',
    host_prize: service?.host_prize || 6,
    price: service?.price || 0,
    prices: service?.prices || {},
    products: service?.products || [],
    sla: service?.sla || null
  })
  const handleChange = useCallback((service: ServiceType) => {
    setState(service)
  }, [])
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    onSubmit?.(state)
    if (client?.id) {
      const body = toFormData({
        ...state,
        client: {
          id: client?.id
        }
      })
      if (service) {
        fetch(`/api/services/${service.id}`, { body, method: 'POST' })
          .then(response => {
            if (response.ok) {
              window.location.reload()
            } else {
              throw new Error()
            }
          })
          .catch(() => alert('Falha ao atualizar serviço!'))
      } else {
        fetch(`/api/services`, { body, method: 'POST' })
          .then(response => {
            if (response.ok) {
              window.location.reload()
            } else {
              throw new Error()
            }
          })
          .catch(() => alert('Falha ao cadastrar serviço!'))
      }
    } else {
      context.current.close()
    }
  }
  return (
    <Modal size="lg">
      <ModalHeader title="Alterar serviço" />
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <ContractServiceItem className={classes.service} onChange={handleChange} service={state} />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSubmit}>SALVAR</Button>
      </ModalFooter>
    </Modal>
  )
}
