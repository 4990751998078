export enum SessionConfirmation {
  NOT_CONFIRMED = 1,
  CANCELED = 2,
  PENDING = 3,
  CONFIRMED = 4
}

// export function getSessionStatusColor(status: SessionStatus) {
//   switch (status) {
//     case SessionStatus.NOT_SCHEDULED:
//       return '#212121'
//     case SessionStatus.SCHEDULED:
//       return '#2196f3'
//     case SessionStatus.CANCELED:
//       return '#f44336'
//     case SessionStatus.PENDING:
//       return '#fbc02d'
//     case SessionStatus.DELIVERED:
//       return '#00b200'
//     case SessionStatus.NOSHOW_CLIENT:
//       return '#9e9e9e'
//     case SessionStatus.NOSHOW_PROVIDER:
//       return '#9e9e9e'
//     case SessionStatus.AT_REVISION:
//       return '#f4511e'
//     case SessionStatus.CONFIRMED:
//       return '#00796b'
//     default:
//       return ''
//   }
// }

// export function getSessionStatusDescription(status: SessionStatus) {
//   switch (status) {
//     case SessionStatus.NOT_SCHEDULED:
//       return 'Não agendado'
//     case SessionStatus.SCHEDULED:
//       return 'Agendado'
//     case SessionStatus.CANCELED:
//       return 'Cancelado'
//     case SessionStatus.PENDING:
//       return 'Pendente'
//     case SessionStatus.DELIVERED:
//       return 'Entregue'
//     case SessionStatus.NOSHOW_CLIENT:
//       return 'Cliente no-show'
//     case SessionStatus.NOSHOW_PROVIDER:
//       return 'Fornecedor no-show'
//     case SessionStatus.AT_REVISION:
//       return 'Em revisão'
//     case SessionStatus.CONFIRMED:
//       return 'Confirmado'
//   }
// }
