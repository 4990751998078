import React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { useAuthContext } from 'components/auth/AuthContext'

import { Report } from './report/Report'

export const Finantial: React.FC<RouteComponentProps> = ({ match }) => {
  const [user] = useAuthContext()
  if (user?.permissions.finantial === false) {
    return <Redirect to="/" />
  }
  return (
    <Switch>
      <Route path={`${match.path}`} component={Report} />
    </Switch>
  )
}
