import React from 'react'

import { useProviderContext } from '../ProviderContext'

export const ProviderDocuments: React.FC = () => {
  const [provider, setProvider] = useProviderContext()
  return (
    <div>
      
    </div>
  )
}