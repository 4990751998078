import React, { Fragment, useEffect, useState } from 'react'

import { useModal } from 'shared/modal'
import { Button } from 'shared/ui/button'
import { FormFeedbackSuccess } from 'shared/form/FormFeedbackSuccess'
import { FormGroup } from 'shared/form/FormGroupNew'
import { InputTextArea } from 'shared/form/InputTextArea'
import { toFormData } from 'shared/util/form-data'

import { TransactionModal } from 'components/users/TransactionModal'

import classes from './ClientFinantial.module.scss'

import * as S from '../styled'

const handleDateWithTime = (handle: any) => {
  let date = new Date(handle)
  return !date.getDate() ? '' : date.toLocaleString('pt-BR').slice(0, 16)
}

export const ClientFinantial: App.ClientViewSection = ({ state: [client, setClient] }) => {
  const [transactions, setTransactions] = useState<Model.Transaction[]>([])
  const [info, setInfo] = useState({
    finantial: client.info.finantial || ''
  })
  const [status, setStatus] = useState<App.FormStatus>({ state: 'initial' })
  const handleChange = (key: keyof typeof info) => (value: string) => {
    setInfo(info => ({ ...info, [key]: value }))
  }
  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    const body = toFormData({ info })
    setStatus({ state: 'loading' })
    fetch(`/api/clients/${client.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          setStatus({ state: 'saved' })
        } else {
          throw new Error()
        }
      })
      .catch(() => alert('Falha ao atualizar cliente!'))
  }
  const modal = useModal(TransactionModal)

  useEffect(() => {
    fetch(`/api/clients/${client?.id}/transactions`)
      .then(res => res.json())
      .then(({ data }) => setTransactions(data))
  }, [client])

  const openNewTransactionModal = () =>
    modal.open({
      client,
      onClosed: () => {
        fetch(`/api/clients/${client?.id}/transactions`)
          .then(res => res.json())
          .then(({ data }) => setTransactions(data))
      }
    })

  return (
    <Fragment>
      <FormGroup label="Informações financeiras">
        <InputTextArea onChange={handleChange('finantial')} initial={info.finantial} rows={6} />
      </FormGroup>
      <div className={classes.footer}>
        <Button className={classes.button} disabled={status.state === 'loading'} onClick={handleSubmit} size="sm">
          SALVAR
        </Button>
        <FormFeedbackSuccess autoHide={5} status={status} />
      </div>
      <hr />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={openNewTransactionModal} outline size="sm">
          CRIAR TRANSAÇÃO
        </Button>
      </div>
      <S.Table className={'transactions'}>
        <S.ItemTable className={'first-line'}>Data</S.ItemTable>
        <S.ItemTable className={'first-line'}>Usuário</S.ItemTable>
        <S.ItemTable className={'first-line'}>Valor</S.ItemTable>
        <S.ItemTable className={'first-line'}>Balanço</S.ItemTable>
        <S.ItemTable className={'first-line'} />
        {transactions.map((transaction, index) => (
          <Fragment key={index}>
            <S.ItemTable>{handleDateWithTime(transaction?.created_at)}</S.ItemTable>
            <S.ItemTable>{transaction?.user?.name}</S.ItemTable>
            <S.ItemTable>R${transaction?.value}</S.ItemTable>
            <S.ItemTable>R${transaction?.balance}</S.ItemTable>
            <S.ItemTable>{transaction?.description}</S.ItemTable>
          </Fragment>
        ))}
      </S.Table>
    </Fragment>
  )
}
