import React, { Fragment, createElement, useState } from 'react'
import { NavLink, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Button } from 'shared/bootstrap/button'
import { Card, CardBody } from 'shared/bootstrap/card'
import { FormGroup } from 'shared/form/FormGroupNew'
import { InputAutocomplete } from 'shared/form/InputAutocomplete'
import { InputRadio } from 'shared/form/InputRadio'
import { InputRadioContextProvider } from 'shared/form/InputRadioContext'
import { UserDataSource } from 'shared/hooks/source'
import { Breadcrumb, BreadcrumbItem } from 'shared/ui/breadcrumb'
import { toFormData } from 'shared/util/form-data'

import { useClient } from 'hooks/data/client'

import { ClientCosts } from './tabs/ClientCosts'
import { ClientFinantial } from './tabs/ClientFinantial'
import { ClientInfo } from './tabs/ClientInfo'
import { ClientOrientations } from './tabs/ClientOrientations'
import { ClientServices } from './tabs/ClientServices/ClientServices'
import { ClientSessions } from './tabs/ClientSessions/ClientSessions'
import { ClientUsers } from './tabs/ClientUsers/ClientUsers'

import classes from './ClientView.module.scss'

export const ClientView: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const [client, setClient] = useClient(match.params)
  const [value, setValue] = useState<Partial<Model.Client>>({})
  const state = [client!, setClient] as App.ReactState<Model.Client>
  const section = (component: React.FC<{ state: App.ReactState<Model.Client> }>) => ({
    render: () => createElement(component, { state })
  })

  const handleSave: React.MouseEventHandler = event => {
    if (client) {
      const body = toFormData(value)
      fetch(`/api/clients/${client.id}`, { body, method: 'POST' })
        .then(res => res.json())
        .then(({ data }) => setClient(data))
    }
  }
  const handleValue = (key: string) => (value: string | number) => {
    setValue(previous => ({ ...previous, [key]: value }))
  }
  const clientPaymentPlan = value.payment_plan || client?.payment_plan
  return (
    <Fragment>
      {client && (
        <Fragment>
          <Breadcrumb>
            <BreadcrumbItem to="/clients">Clientes</BreadcrumbItem>
            <BreadcrumbItem>Cliente {client.id}</BreadcrumbItem>
          </Breadcrumb>
          <div className={classes.container}>
            <div className={classes.info}>
              <Card shadow>
                <CardBody>
                  <h1>{client.name}</h1>
                  {client.city && <h2>{client.city.name}</h2>}
                  <hr />
                  <p>ID {client.id}</p>
                  <p className={classes.address}>{client.address?.formatted_address || '-'}</p>
                  {client.city && (
                    <p>
                      {client.city.name}/{client.city.state}
                    </p>
                  )}
                  <p className={classes.corporateName}>Razão Social: {client.corporate_name}</p>
                  <p>CNPJ: {client.identification}</p>
                </CardBody>
              </Card>
              <Card shadow>
                <CardBody>
                  <FormGroup label="SDR">
                    <UserDataSource associableType="admin" profile={1 << 5}>
                      <InputAutocomplete
                        onChange={handleValue('sales_representative_id')}
                        placeholder={client.sales_representative?.name}
                      />
                    </UserDataSource>
                  </FormGroup>
                  <br />
                  <FormGroup label="BDR">
                    <UserDataSource associableType="admin" profile={1 << 6}>
                      <InputAutocomplete
                        onChange={handleValue('business_representative_id')}
                        placeholder={client.business_representative?.name}
                      />
                    </UserDataSource>
                  </FormGroup>
                  <hr />
                  <h2>Plano de Pagamento</h2>
                  <InputRadioContextProvider>
                    <InputRadio onChange={handleValue('payment_plan')} checked={clientPaymentPlan === 1} value="1">
                      Pré-pago
                    </InputRadio>
                    <InputRadio onChange={handleValue('payment_plan')} checked={clientPaymentPlan === 2} value="2">
                      Pós-pago
                    </InputRadio>
                  </InputRadioContextProvider>
                  <br />
                  <Button onClick={handleSave}>SALVAR</Button>
                </CardBody>
              </Card>
            </div>

            <Card className={classes.sections} shadow>
              <CardBody>
                <nav className={classes.nav}>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/sessions`}>
                    Ensaios
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/services`}>
                    Serviços
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/users`}>
                    Usuários
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/orientations`}>
                    Orientações ao fornecedor
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/info`}>
                    Dados básicos
                  </NavLink>
                  <NavLink className={classes.link} activeClassName={classes.active} to={`${match.url}/finantial`}>
                    Financeiro
                  </NavLink>
                </nav>
                <Switch>
                  <Route path={`${match.path}/costs`} {...section(ClientCosts)} />
                  <Route path={`${match.path}/info`} {...section(ClientInfo)} />
                  <Route path={`${match.path}/orientations`} {...section(ClientOrientations)} />
                  <Route path={`${match.path}/services`} {...section(ClientServices)} />
                  <Route path={`${match.path}/sessions`} {...section(ClientSessions)} />
                  <Route path={`${match.path}/users`} {...section(ClientUsers)} />
                  <Route path={`${match.path}/finantial`} {...section(ClientFinantial)} />
                  <Redirect to={`${match.url}/sessions`} />
                </Switch>
              </CardBody>
            </Card>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
