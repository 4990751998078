import React, { useContext, useEffect } from 'react'
import { useUserContext } from './users'
import { useLoadingContext } from './loading'

function useMeta(id: any): [any] {
  const [, setLoading] = useLoadingContext()
  const [, setUsers] = useUserContext()
  const filter = { clients: [id] }

  const reducer = (action: string) => {
    switch (action) {
      case 'update':
        setLoading(true)
        fetch(`/api/users/search`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ filter })
        })
          .then(res => res.json())
          .then(({ data }) => setUsers(data?.users))
          .then(() => setLoading(false))
        break
    }
  }

  const update = () => reducer('update')

  // eslint-disable-next-line
  useEffect(update, [])

  return [reducer]
}

const MetaContext = React.createContext<[any] | undefined>(undefined)

export const useMetaContext = () => useContext(MetaContext)!

export const MetaProvider: React.FC<{ id: any }> = props => {
  const meta = useMeta(props.id)

  return <MetaContext.Provider value={meta} {...props} />
}
