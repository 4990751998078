import React, { useEffect, useRef, useState } from 'react'

import { classNames } from '../util/class-names'

interface FormAddressProps {
  className?: string
  initial?: object & { formatted_address?: string } | null
  onChange?: (_: google.maps.places.PlaceResult) => void
  placeholder?: string
  required?: boolean
  type?: 'address' | 'city' | 'cities'
  value?: string
}

export const InputAddress: React.FC<FormAddressProps> = ({
  className,
  initial,
  onChange,
  value,
  type = 'address',
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const useType = type === 'city' || type === 'cities' ? '(cities)' : type
  const autocomplete = useRef<google.maps.places.Autocomplete>()
  const input = useRef<HTMLInputElement>(null)
  const observer = useRef(new IntersectionObserver(() => setVisible(true), { threshold: 0.5 }))
  useEffect(() => {
    if (!visible && input.current) {
      observer.current.observe(input.current)
    } else if (visible && input.current && !Boolean(autocomplete.current)) {
      observer.current.disconnect()
      autocomplete.current = new google.maps.places.Autocomplete(input.current, {
        componentRestrictions: {
          country: ['BR']
        }
      })
      autocomplete.current.addListener('place_changed', () => {
        if (autocomplete.current) {
          const place = autocomplete.current.getPlace()
          onChange?.(JSON.parse(JSON.stringify(place)))
        }
      })
    }
  }, [onChange, useType, visible])
  useEffect(() => {
    if (input.current && value) {
      input.current.value = value
    }
  }, [value])
  const params = {
    className: classNames('form-control', className),
    defaultValue: initial?.formatted_address,
    name: 'address',
    placeholder: type === 'cities' || type === 'city' ? 'São Paulo, SP' : 'Paulista, 1578',
    ref: input
  }
  return <input {...params} {...props} />
}

// import React, { useEffect, useState } from 'react'

// import { AddressDataSource } from '../hooks/source/AddressDataSource'
// // import { Icon } from '../../ui/icon'
// // import { classNames } from '../../util/class-names'

// // import { DropdownItem } from '../dropdown/DropdownItem'
// // import { DropdownMenu } from '../dropdown/DropdownMenu'

// // import { useFormGroupContext } from './FormGroupNew'

// import { InputAutocomplete } from './InputAutocomplete'

// import classes from './InputAddress.module.scss'

// interface InputAddressProps {
//   onChange?: (_: number | string) => void
//   placeholder?: string
//   required?: boolean
//   type: 'address' | 'city'
//   value?: google.maps.GeocoderResult
// }

// interface ItemProps {}

// const Item: React.FC<ItemProps> = () => {
//   return <div>teste</div>
// }

// export const InputAddress: React.FC<InputAddressProps> = ({ onChange, type, ...props }) => {
//   return (
//     <div className={classes.address}>
//       <AddressDataSource type={type}>
//         <InputAutocomplete renderItem={Item} />
//       </AddressDataSource>
//     </div>
//   )
// }
