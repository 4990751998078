import React, { useEffect, useState } from 'react'

import { useItems } from 'hooks/data/item'
import { FormGroup } from 'shared/form/FormGroup'
import { FormText } from 'shared/form/FormText'
import { InputDecimal } from 'shared/form/InputDecimal'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { classNames } from 'shared/util/class-names'

import classes from './ContractServiceItem.module.scss'
import { FormTag } from 'shared/form/FormTag'

interface ContractServiceItemProps {
  className?: string
  index?: number
  onChange?: (_: any) => void
  service: Partial<Model.Service>
}

export const ContractServiceItem: React.FC<ContractServiceItemProps> = ({ className, index, onChange, service }) => {
  const [state, setState] = useState<Partial<Model.Service>>(service)
  const items = useItems().filter(item => !item.item)

  useEffect(() => {
    if (!state.products) {
      setState(state => ({ ...state, products: [] }))
    }
  }, [state])

  const [productTags, setProductTags] = useState(() => {
    const product = state?.products?.find(({ item }) => item?.taggable)
    const defaults = ['Destaque', 'Salas', 'Cozinha', 'Banheiro', 'Dormitório', 'Áreas Comuns', 'Fachada']
    return product?.tags?.map(tag => tag.name ?? '') || defaults
  })

  const handleChange = (key: string) => (value: any) => {
    setState(state => ({ ...state, [key]: value }))
  }
  const handlePricesChange = (key: string) => (value: number) => {
    setState(({ prices, ...state }) => ({ ...state, prices: { ...prices, [key]: value } }))
  }
  const handleTagChange = (tags: string[]) => {
    setProductTags(tags)
    setState(state => {
      const products = state?.products?.map(product => {
        if (product.item?.taggable) {
          return Object.assign(product, { tags: tags.map(tag => ({ name: tag })) })
        } else {
          return product
        }
      })
      return { ...state, products: products || [] }
    })
  }

  // this handles if is selected or not state.products
  const handleItem = (item: Model.Item) => (_: React.MouseEvent) => {
    if (state.products) {
      const index = state.products.findIndex(product => product.item!.id === item.id)

      if (index >= 0) {
        // Remove the item
        const products = state.products
        setState(service => {
          return { ...service, products: products.filter(product => product.item!.id !== item.id) }
        })
      } else {
        setState(({ products = [], ...service }) => {
          if ([1, 2, 12, 20, 25, 27, 43, 45].includes(item.id)) {
            // Allow only one main deliverable
            const deliverableIndex = products.findIndex(product => {
              return product.item && product.item.id !== item.id && [1, 2, 12, 20, 25, 27, 43, 45].includes(product.item.id)
            })
            if (deliverableIndex >= 0) {
              products.splice(deliverableIndex, 1)
            }
          }
          const state = { ...service, products: [...products, { item_id: item!.id!, item, name: item!.title }] }
          state.products = state.products.map(product => {
            if (product.item?.taggable) {
              product.tags = productTags.map(name => ({ name }))
            }
            return product
          })
          console.log(state)
          return state
        })
      }
    }

    setProductTags(productTags)
  }

  const getItemClass = (item: Model.Item) => {
    return classNames(state.products?.find(({ item_id }) => item_id === item.id) && classes.selected)
  }
  useEffect(() => {
    onChange?.(state)
    // eslint-disable-next-line
  }, [state])
  return (
    <div className={classNames(classes.wrapper, className)}>
      {index !== undefined && <h3 className={classes.title}>SERVIÇO {index + 1}</h3>}
      <FormGroup label="Nome do serviço">
        <FormText onChange={handleChange('name')} value={state.name} required />
      </FormGroup>
      <div className={classes.price}>
        <FormGroup label="Valor do Serviço Realizado">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal initial={state.price || ''} onChange={handleChange('price')} scale={2} />
          </InputGroup>
        </FormGroup>
        <FormGroup label="Prazo de Entrega">
          <InputDecimal initial={state.sla || ''} onChange={handleChange('sla')} />
        </FormGroup>
        <FormGroup label="Hospedagem do Tour">
          <InputGroup>
            <InputDecimal initial={state.host_prize || 6} onChange={handleChange('host_prize')} required />
            <InputGroupText>meses</InputGroupText>
          </InputGroup>
        </FormGroup>
      </div>
      <div className={classes.taxes}>
        <FormGroup label="Valor Cancelamento sem Antecedência">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal initial={state.prices?.charged || ''} onChange={handlePricesChange('charged')} scale={2} />
          </InputGroup>
        </FormGroup>
        <FormGroup label="Valor Deslocamento">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal
              initial={state.prices?.displacement || ''}
              onChange={handlePricesChange('displacement')}
              scale={2}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup label="Valor Área Adicional">
          <InputGroup>
            <InputGroupText>R$</InputGroupText>
            <InputDecimal
              initial={state.prices?.additional_area || ''}
              onChange={handlePricesChange('additional_area')}
              scale={2}
            />
          </InputGroup>
        </FormGroup>
      </div>
      <h3 className={classes.title}>ENTREGÁVEL PRINCIPAL</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => [1, 2, 12, 20, 25, 27, 43, 45].includes(item.id))
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <h3 className={classes.title}>ENTREGÁVEIS ADICIONAIS</h3>
      <ul className={classes.items}>
        {items
          ?.filter(item => ![1, 2, 12, 20, 25, 27, 43, 45].includes(item.id))
          .map((item, i) => (
            <li key={i} className={getItemClass(item)} onClick={handleItem(item)}>
              <img className={classes.icon} src={`https://assets.birdie.com.br/${item.icon}`} alt={item.title} />
              <p>{item.title}</p>
            </li>
          ))}
      </ul>
      <FormGroup>
        <h3>CATEGORIAS PARA FOTOS</h3>
        <small>Separe as tags com vírgula</small>
        <FormTag readOnly={state?.products?.[0] === undefined} onChange={handleTagChange} value={productTags} />
      </FormGroup>
    </div>
  )
}
