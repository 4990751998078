import React from 'react'

import { classNames } from 'shared/util/class-names'

import classes from './Navbox.module.scss'

interface Props {
  opened: boolean
}

export const Navbox: React.FC<Props> = ({ opened, ...props }) => (
  <div className={classNames(classes.navbox, opened && classes.opened)} {...props} />
)
