import React, { Fragment, useState } from 'react'

import { Button } from 'shared/bootstrap/button'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/bootstrap/modal'
import { ProviderDataSource } from 'shared/hooks/source/ProviderDataSource'
import { InputAutocomplete } from 'shared/form/InputAutocomplete'
import { InputDecimal } from 'shared/form/InputDecimal'
import { InputGroup } from 'shared/form/InputGroup'
import { InputGroupText } from 'shared/form/InputGroupText'
import { toFormData } from 'shared/util/form-data'

import classes from './SessionProviderModal.module.scss'
interface Props {
  session: Resource.SessionResource
}

export const SessionProviderModal: React.FC<Props> = ({ session }) => {
  const [costs, setCosts] = useState(new Map<number, number>())
  const [providers, setProviders] = useState(new Map<number, number>())
  const handleProvider = (productId: number) => (providerId: number | string) => {
    setProviders(providers => {
      const product = session.products.find(p => p.id === productId)
      const value = new Map(providers)
      if (product?.provider.id === providerId) {
        value.delete(productId)
      } else {
        value.set(productId, Number(providerId))
      }
      return value
    })
  }
  const handleCost = (productId: number) => (cost: number) => {
    setCosts(costs => {
      const value = new Map(costs)
      return value.set(productId, cost)
    })
  }
  const handleSave = (event: React.MouseEvent) => {
    event.preventDefault()
    const products = [] as any[]
    Array.from(providers).forEach(([productId, providerId]) => {
      const index = products.findIndex(({ id }) => id === productId)
      if (index >= 0) {
        Object.assign(products[index], { provider_id: providerId })
      } else {
        products.push({ id: productId, provider_id: providerId })
      }
    })
    Array.from(costs).forEach(([productId, cost]) => {
      const index = products.findIndex(({ id }) => id === productId)
      if (index >= 0) {
        Object.assign(products[index], { cost })
      } else {
        products.push({ id: productId, cost })
      }
    })
    const body = toFormData({ products })
    fetch(`/api/sessions/${session.id}`, { body, method: 'POST' })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        } else {
          throw Error()
        }
      })
      .catch(() => alert('Falha ao salvar fornecedores do ensaio!'))
  }
  return (
    <Modal className={classes.modal} contentClassName={classes.content} size="lg">
      <ModalHeader title="Alterar fornecedores do ensaio" />
      <ModalBody className={classes.body}>
        <div className={classes.grid}>
          {session.products.map(
            product =>
              product.item.work_type !== 4 && (
                <Fragment key={product.id}>
                  <p className={classes.label}>{product.item.label}</p>
                  <ProviderDataSource itemId={product.item.id}>
                    <InputAutocomplete
                      empty
                      onChange={handleProvider(product.id)}
                      defaultValue={product.provider.name || undefined}
                    />
                  </ProviderDataSource>
                  <InputGroup>
                    <InputGroupText>R$</InputGroupText>
                    <InputDecimal
                      initial={product.cost}
                      onChange={handleCost(product.id)}
                      placeholder="Custo"
                      scale={2}
                    />
                  </InputGroup>
                </Fragment>
              )
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleSave}>SALVAR</Button>
      </ModalFooter>
    </Modal>
  )
}
